export default {
	'user.whatsYourName': 'Как вас зовут?',
	'user.niceToMeetYou': 'Приятно познакомиться, {name}!',
	'user.hello': 'Привет!',
	'user.username': 'Имя пользователя',
	'user.defaultUsername': 'Подозрительный незнакомец',

	'user.fullName': 'Полное имя',
	'user.anonymous': 'Анонимно',
	'user.email': 'Email',
	'user.yourEmail': 'Ваш Email',
	'user.feedback': 'Отзыв',
	'user.yourFeedback': 'Ваш отзыв',
	'user.chooseTopic': 'Выберите тему',

	'user.validationInvalidEmail':
		'Пожалуйста, введите правильный адрес электронной почты',
	'user.validationFillOutThisField': 'Пожалуйста, заполните это поле',
	'user.feedbackSuccess': 'Отзыв успешно отправлен!',
	'user.feedbackFail':
		'Не удалось отправить отзыв. Пожалуйста, попробуйте позже.',
	'user.feedbackSubmitting': 'Отправка...',

	'user.sendFeedback': 'Отправить отзыв',
	'user.feedbackTopicGeneral': 'Отзыв',
	'user.feedbackTopicBug': 'Сообщение об ошибке',
	'user.feedbackTopicComplaint': 'Жалоба',
	'user.feedbackTopicFeature': 'Запрос нового функционала',
	'user.feedbackTopicContact': 'Связаться с разработчиком',
	'user.feedbackTopicOther': 'Другое',
};
