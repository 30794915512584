export default function Minimize() {
	return (
		<svg
			width="1em"
			height="1em"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M2.99976 8H3.19976C4.87991 8 5.71999 8 6.36173 7.67302C6.92621 7.3854 7.38516 6.92646 7.67278 6.36197C7.99976 5.72024 7.99976 4.88016 7.99976 3.2V3M2.99976 16H3.19976C4.87991 16 5.71999 16 6.36173 16.327C6.92621 16.6146 7.38516 17.0735 7.67278 17.638C7.99976 18.2798 7.99976 19.1198 7.99976 20.8V21M15.9998 3V3.2C15.9998 4.88016 15.9998 5.72024 16.3267 6.36197C16.6144 6.92646 17.0733 7.3854 17.6378 7.67302C18.2795 8 19.1196 8 20.7998 8H20.9998M15.9998 21V20.8C15.9998 19.1198 15.9998 18.2798 16.3267 17.638C16.6144 17.0735 17.0733 16.6146 17.6378 16.327C18.2795 16 19.1196 16 20.7998 16H20.9998"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
