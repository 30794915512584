import { TimerCycles, TimerCyclesWithoutIdle } from '@entities/timer';

// ---

const shadowClasses =
	'bg-gradient-to-br absolute rounded-full filter blur-3xl opacity-0 duration-300';

const cycleToLightBackgroundClass = {
	[TimerCycles.WORK]:
		'bg-gradient-to-br bg-no-repeat bg-cover from-red-500 to-purple-300 duration-300',
	[TimerCycles.BREAK]:
		'bg-gradient-to-br from-blue-300 to-green-400 duration-300',
	[TimerCycles.LONG_BREAK]:
		'bg-gradient-to-br from-rose-200 to-blue-500 duration-300',
};

const cycleToDarkBackgroundClass = {
	[TimerCycles.WORK]: ' to-purple-300 from-red-500',
	[TimerCycles.BREAK]: 'from-blue-300 to-green-400',
	[TimerCycles.LONG_BREAK]: 'from-rose-200 to-blue-500',
};

interface BackgroundsProps {
	selectedCycle: TimerCyclesWithoutIdle;
}

export default function Backgrounds({ selectedCycle }: BackgroundsProps) {
	return (
		<div>
			<div className="absolute left-0 top-0 h-full w-full overflow-hidden">
				<div
					className={`absolute left-0 top-0 h-full w-full bg-baseStrong-800`}
				></div>
				<div
					className={`absolute left-0 top-0 h-full w-full dark:opacity-0 ${cycleToLightBackgroundClass[selectedCycle]}`}
				></div>
				<div
					className={`${shadowClasses} ${cycleToDarkBackgroundClass[selectedCycle]} left-1/2 top-1/2 h-[90%] w-3/6 translate-x-[-50%] translate-y-[-50%] rotate-45 lg:h-[80%] lg:w-1/12 dark:opacity-100`}
				></div>
				<div
					className={`${shadowClasses} ${cycleToDarkBackgroundClass[selectedCycle]} left-1/2  top-1/2 h-[65%] w-4/6 translate-x-[-50%] translate-y-[-50%] rotate-[-45deg] lg:h-[60%] lg:w-2/12 dark:opacity-100`}
				></div>
			</div>
		</div>
	);
}
