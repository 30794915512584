import { useEffect, useState } from 'react';

// ---

export default function useVibration(windTime: number, isFinished: boolean) {
	const [skip, setSkip] = useState(true);
	const canVibrate = 'vibrate' in navigator;

	useEffect(() => {
		if (skip) {
			setSkip(false);
			return;
		}
		if (canVibrate) {
			navigator.vibrate(50);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [windTime]);

	useEffect(() => {
		if (isFinished && canVibrate) {
			navigator.vibrate([100, 50, 100, 50, 100]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFinished]);
}
