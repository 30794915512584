import { useMemo, useState } from 'react';
import { Key } from 'react-aria-components';
import { useSelector } from 'react-redux';

import { HistoryEntryView, HistoryEntry } from '@entities/history';
import { TimerCycles, CycleLabel } from '@entities/timer';
import { Select } from '@shared/ui';
import { I18nMessage } from '@shared/i18n';
import { selectLanguage } from '@entities/setting';

import groupByDay from './group-by-day';

// ---

interface HistoryLogProps {
	entries: HistoryEntry[];
}

const INITIAL_CYCLE_FILTER = TimerCycles.WORK;

const filterItems = [
	{
		label: <I18nMessage id="shared.all" />,
		value: 'all',
	},
	{
		label: <I18nMessage id="timer.work" />,
		value: TimerCycles.WORK,
	},
	{
		label: <I18nMessage id="timer.break" />,
		value: TimerCycles.BREAK,
	},
	{
		label: <I18nMessage id="timer.longBreak" />,
		value: TimerCycles.LONG_BREAK,
	},
];

function filterByCycle(entry: HistoryEntry, cycle: TimerCycles | 'all') {
	if (cycle === 'all') {
		return true;
	}

	return entry.cycle === cycle;
}

export default function HistoryLog({ entries }: HistoryLogProps) {
	const [selectedFilter, setSelectedFilter] = useState<TimerCycles | 'all'>(
		INITIAL_CYCLE_FILTER
	);
	const locale = useSelector(selectLanguage);
	const dateTimeFormatter = useMemo(
		() =>
			new Intl.DateTimeFormat(locale, {
				weekday: 'short',
				month: 'short',
				day: 'numeric',
				year: 'numeric',
			}),
		[locale]
	);

	const groupedByDay = groupByDay(
		entries
			.toReversed()
			.filter((entry) => filterByCycle(entry, selectedFilter) && entry.endedAt),
		dateTimeFormatter
	);

	return (
		<div className="flex max-h-full grow flex-col">
			<div>
				<Select
					value={selectedFilter}
					onSelectionChange={(value: Key) =>
						setSelectedFilter(value as TimerCycles | 'all')
					}
					items={filterItems}
				/>
			</div>
			<div className="h-[calc(100%-3rem-4px)] max-h-full grow overflow-y-auto">
				{groupedByDay.map(([date, entries]) => (
					<section className="mt-4" key={date}>
						<h2 className="font-semibold">{date}</h2>
						{entries.map((entry) => (
							<HistoryEntryView
								key={entry.id}
								entry={entry}
								locale={locale}
								cycleLabel={<CycleLabel cycle={entry.cycle} colored />}
							/>
						))}
					</section>
				))}
			</div>
		</div>
	);
}
