import { Locales } from '@shared/i18n';

import messagesInUkrainian from './uk-UA';
import messagesInEnglish from './en-US';
import messagesInRussian from './ru-RU';

// ---

export default {
	[Locales.ENGLISH]: messagesInEnglish,
	[Locales.RUSSIAN]: messagesInRussian,
	[Locales.UKRAINIAN]: messagesInUkrainian,
};
