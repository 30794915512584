import { HistoryEntry } from '@entities/history';

// ---

export default function groupByDay(
	entries: HistoryEntry[],
	dateTimeFormatter: Intl.DateTimeFormat
) {
	const grouped = entries.reduce(
		(acc, entry) => {
			const date = new Date(entry.startedAt);
			const key = dateTimeFormatter.format(date);
			if (!acc[key]) {
				acc[key] = [];
			}
			acc[key].push(entry);
			return acc;
		},
		{} as Record<string, HistoryEntry[]>
	);

	return Object.entries(grouped);
}
