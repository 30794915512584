import {
	DirectionalLight,
	Group,
	Object3D,
	Object3DEventMap,
	PerspectiveCamera,
	Vector3,
	WebGLRenderer,
} from 'three';
import { ThreeDEnv } from './types';

// ---

const DEFAULT_PIXEL_RATIO = 5;

export class Timer3dEnv implements ThreeDEnv {
	public renderer: WebGLRenderer;
	public scene: Group<Object3DEventMap>;
	public camera: PerspectiveCamera;
	public light: DirectionalLight;

	public entireTimer: Object3D;
	private topHemisphere: Object3D;

	constructor(scene: Group<Object3DEventMap>, canvas: HTMLCanvasElement) {
		this.light = this.createLight();
		this.camera = this.createCamera();
		this.renderer = this.createRenderer(canvas);
		this.scene = scene;

		scene.add(this.light);
		scene.add(this.light.target);

		this.entireTimer = scene.children[0];
		this.topHemisphere = this.entireTimer.children[1];
	}

	getTopHemisphereRotation() {
		return this.topHemisphere.rotation.z;
	}

	rotateTopHemisphere(rotation: number) {
		this.topHemisphere.rotation.z = rotation;
	}

	createCamera() {
		const camera = new PerspectiveCamera(40, 1, 0.1, 400);
		camera.position.set(0, 0.035, 0.14);
		camera.aspect = 1;
		camera.updateProjectionMatrix();
		camera.lookAt(new Vector3(0, 0.035, 0));

		return camera;
	}

	createLight() {
		const color = 0xffffff;
		const intensity = 1.2;
		const light = new DirectionalLight(color, intensity);
		light.position.set(-3, 5, 0);
		light.target.position.set(3, -5, -9);

		return light;
	}

	createRenderer(canvas: HTMLCanvasElement) {
		const renderer = new WebGLRenderer({
			alpha: true,
			antialias: true,
			canvas,
		});
		renderer.setPixelRatio(DEFAULT_PIXEL_RATIO);
		renderer.setClearColor(0x000000, 0);

		return renderer;
	}
}
