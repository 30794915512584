export default function EmptyCircle() {
	return (
		<svg
			fill="currentColor"
			width="1em"
			height="1em"
			viewBox="0 0 1920 1920"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M960 1807.059c-467.125 0-847.059-379.934-847.059-847.059 0-467.125 379.934-847.059 847.059-847.059 467.125 0 847.059 379.934 847.059 847.059 0 467.125-379.934 847.059-847.059 847.059M960 0C430.645 0 0 430.645 0 960s430.645 960 960 960 960-430.645 960-960S1489.355 0 960 0"
				fillRule="evenodd"
			/>
		</svg>
	);
}
