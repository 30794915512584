export default function Checkmark() {
	return (
		<svg
			width="1em"
			height="1em"
			viewBox="0 -1.5 11 11"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<g stroke="none" strokeWidth="1" fill="currentColor" fillRule="evenodd">
				<g transform="translate(-304.000000, -366.000000)">
					<g transform="translate(56.000000, 160.000000)">
						<polygon points="259 207.6 252.2317 214 252.2306 213.999 252.2306 214 248 210 249.6918 208.4 252.2306 210.8 257.3082 206"></polygon>
					</g>
				</g>
			</g>
		</svg>
	);
}
