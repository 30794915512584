import { Form, Key, TextField } from 'react-aria-components';
import { useIntl } from 'react-intl';
import { FormEvent, useState } from 'react';
import { captureMessage } from '@sentry/react';

import {
	Checkmark,
	Input,
	Label,
	Button,
	TextArea,
	Cross,
	Wait,
	Panel,
	FieldError,
	Select,
} from '@shared/ui';
import { I18nMessage } from '@shared/i18n';

import { SITE_KEY } from '@shared/config';

// ---

declare global {
	interface Window {
		grecaptcha: {
			execute: (
				siteKey: string,
				options: { action: string }
			) => Promise<string>;
			ready: (callback: () => void) => void;
		};
	}
}

interface FeedbackFormProps {
	onSuccess: () => void;
	className?: string;
}

const topicKeyToTopicValue = {
	general: 'General Feedback',
	bug: 'But Report',
	complaint: 'Complaint',
	feature: 'Feature Request',
	contact: 'Contact',
	other: 'Other',
};

function validateEmail(email: string, message: string) {
	const re = /\S+@\S+\.\S+/;
	const isValid = re.test(email);
	return isValid ? null : message;
}

function validateText(text: string, message: string) {
	const isValid = text.length > 0;

	return isValid ? null : message;
}

export default function FeedbackForm({
	onSuccess,
	className,
}: FeedbackFormProps) {
	const intl = useIntl();
	const topics = [
		{
			label: intl.formatMessage({ id: 'user.feedbackTopicGeneral' }),
			value: 'general',
		},
		{
			label: intl.formatMessage({ id: 'user.feedbackTopicBug' }),
			value: 'bug',
		},
		{
			label: intl.formatMessage({ id: 'user.feedbackTopicComplaint' }),
			value: 'complaint',
		},
		{
			label: intl.formatMessage({ id: 'user.feedbackTopicFeature' }),
			value: 'feature',
		},
		{
			label: intl.formatMessage({ id: 'user.feedbackTopicContact' }),
			value: 'contact',
		},
		{
			label: intl.formatMessage({ id: 'user.feedbackTopicOther' }),
			value: 'other',
		},
	];

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);
	const [isFail, setIsFail] = useState(false);

	const [formData, setFormData] = useState({
		name: '',
		email: '',
		topic: 'general',
		text: '',
	});

	const handleValueChange = (key: string, value: Key) => {
		setFormData((prevForm) => ({
			...prevForm,
			[key]: value as string,
		}));
	};

	const anonymousMessage = intl.formatMessage({ id: 'user.anonymous' });
	const invalidEmailMessage = intl.formatMessage({
		id: 'user.validationInvalidEmail',
	});
	const noTextMessage = intl.formatMessage({
		id: 'user.validationFillOutThisField',
	});

	const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setIsSubmitting(true);

		function handleError(error: string) {
			captureMessage(error);
			setIsFail(true);
		}

		window.grecaptcha.ready(function () {
			window.grecaptcha
				.execute(SITE_KEY, {
					action: 'submit',
				})
				.then(function (token) {
					fetch(import.meta.env.VITE_FEEDBACK_FORM_URL, {
						method: 'POST',
						body: JSON.stringify({
							...formData,
							'g-recaptcha-response': token,
							topic:
								topicKeyToTopicValue[
									formData.topic as keyof typeof topicKeyToTopicValue
								],
						}),
						headers: {
							Accept: 'application/javascript',
							'Content-Type': 'application/json',
						},
					})
						.then(() => {
							onSuccess();
							setIsSuccess(true);
						})
						.catch((error) => {
							handleError(error);
						})
						.finally(() => {
							setIsSubmitting(false);
							setFormData({
								name: '',
								email: '',
								topic: 'general',
								text: '',
							});
							setTimeout(() => {
								setIsFail(false);
								setIsSuccess(false);
							}, 2000);
						});
				})
				.catch((error) => {
					handleError(error);
					setIsSubmitting(false);
					setTimeout(() => {
						setIsFail(false);
						setIsSuccess(false);
					}, 2000);
				});
		});
	};

	return (
		<Form
			action="https://formkeep.com/f/8b60d7d57f5a"
			encType="multipart/form-data"
			method="post"
			onSubmit={handleSubmit}
			className={`relative ${className}`}
		>
			<h2 className="text-center text-xl font-semibold text-baseStrong-800 dark:text-baseWeak-900">
				<I18nMessage id="user.feedback" />
			</h2>
			{isSubmitting && (
				<Panel
					noShadow
					className="absolute bottom-0 left-0 top-0 flex w-full flex-col justify-center px-4 py-20 text-center text-baseStrong-800 dark:text-baseWeak-900"
				>
					<div className="flex justify-center text-6xl">
						<Wait />
					</div>
					<p className="font-semibold">
						<I18nMessage id="user.feedbackSubmitting" />
					</p>
				</Panel>
			)}
			{isSuccess && (
				<Panel
					noShadow
					className="absolute bottom-0 left-0 top-0 flex w-full flex-col justify-center px-4 py-20 text-center text-emerald-700"
				>
					<div className="flex justify-center text-6xl">
						<Checkmark />
					</div>
					<p className="font-semibold">
						<I18nMessage id="user.feedbackSuccess" />
					</p>
				</Panel>
			)}
			{isFail && (
				<Panel
					noShadow
					className="absolute bottom-0 left-0 top-0 flex w-full flex-col justify-center px-4 py-20 text-center text-rose-700"
				>
					<div className="flex justify-center text-6xl">
						<Cross />
					</div>
					<p className="font-semibold">
						<I18nMessage id="user.feedbackFail" />
					</p>
				</Panel>
			)}
			<div className={`${isSuccess ? 'opacity-0' : ''} w-full`}>
				<div className="mt-5 flex gap-2">
					<div className="flex-1">
						<Label htmlFor="feedbackName">
							<I18nMessage id="user.fullName" />
						</Label>
						<Input
							size="lg"
							placeholder={anonymousMessage}
							id="feedbackName"
							value={formData.name}
							maxLength={40}
							onChange={(event) =>
								handleValueChange('name', event.target.value)
							}
						/>
					</div>
					<div className="flex-1">
						<TextField
							name="email"
							type="email"
							validate={(value) => validateEmail(value, invalidEmailMessage)}
						>
							<Label htmlFor="feedbackEmail">
								<I18nMessage id="user.email" />
							</Label>

							<Input
								type="email"
								size="lg"
								placeholder={anonymousMessage}
								id="feedbackEmail"
								maxLength={100}
								value={formData.email}
								onChange={(event) =>
									handleValueChange('email', event.target.value)
								}
							/>
							<FieldError />
						</TextField>
					</div>
				</div>

				<Select
					className="mt-3"
					label={intl.formatMessage({ id: 'user.chooseTopic' })}
					value={formData.topic}
					onSelectionChange={(newTopic: Key) =>
						handleValueChange('topic', newTopic)
					}
					items={topics}
				/>

				<TextField
					name="text"
					isRequired
					validate={(value) => validateText(value, noTextMessage)}
				>
					<Label className="mt-3 block" htmlFor="feedbackText">
						<I18nMessage id={'user.yourFeedback'} />
					</Label>
					<TextArea
						placeholder="..."
						id="feedbackText"
						value={formData.text}
						onChange={(event) => handleValueChange('text', event.target.value)}
					/>
					<FieldError />
				</TextField>

				<div className="flex justify-end">
					<Button
						disabled={isSubmitting}
						className="mt-3"
						size="md"
						htmlType="submit"
					>
						<I18nMessage id={'shared.submit'} />
					</Button>
				</div>
			</div>
		</Form>
	);
}

FeedbackForm.defaultProps = {
	onSuccess: () => {},
	className: '',
};
