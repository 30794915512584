import { ReactNode } from 'react';
import { Checkbox as AriaCheckbox } from 'react-aria-components';

import { Done } from '@shared/ui';

// ---

interface CheckboxProps {
	label?: ReactNode;
	onToggle: (newState: boolean) => void;
	checked: boolean;
	disabled?: boolean;
}

export default function Checkbox({
	label,
	onToggle,
	checked,
	disabled,
}: CheckboxProps) {
	// Temporary hack to avoid an issue with unit tests where onToggle is called although "disabled" is true
	const handleToggle = (newValue: boolean) => {
		if (disabled) {
			return;
		}

		onToggle(newValue);
	};

	return (
		<AriaCheckbox
			isSelected={checked}
			isDisabled={disabled}
			className="group/checkbox flex items-center gap-2"
			onChange={handleToggle}
		>
			<span className="border-gray-250 flex h-7 w-7 items-center justify-center rounded-3xl border-2 text-white shadow-sm group-data-[selected=true]/checkbox:border-primary-500 group-data-[selected=true]/checkbox:bg-primary-500 dark:border-baseStrong-600 dark:text-baseStrong-800 dark:shadow-none">
				<span className="opacity-0 group-data-[selected=true]/checkbox:opacity-100">
					<Done />
				</span>
			</span>
			<span>{label}</span>
		</AriaCheckbox>
	);
}

Checkbox.defaultProps = {
	disabled: false,
};
