import { useDispatch, useSelector } from 'react-redux';
import { Key } from 'react-aria-components';
import { useIntl } from 'react-intl';

import {
	Tab,
	TabList,
	TabPanel,
	Tabs,
	Panel,
	CloseDrawerButton,
} from '@shared/ui';
import { HistoryLog } from '@features/history';
import {
	addTodo,
	editTodo,
	moveTodoToTop,
	removeAll,
	removeCompleted,
	removeTodo,
	selectTodos,
	toggleComplete,
	TodoWidget,
} from '@entities/todo';
import { selectHistoryEntries } from '@entities/history';

// ---

const openTabStorageKey = 'historyTodoDrawerTab';

interface HistoryTodoDrawerProps {
	onClose: () => void;
}

export default function HistoryTodoDrawer({ onClose }: HistoryTodoDrawerProps) {
	const dispatch = useDispatch();
	const todos = useSelector(selectTodos);
	const historyEntries = useSelector(selectHistoryEntries);

	const intl = useIntl();

	const historyLabel = intl.formatMessage({ id: 'history.history' });
	const todoLabel = intl.formatMessage({ id: 'todo.todos' });

	return (
		<Panel className="flex max-h-full w-[97vw] flex-col rounded-r-none sm:w-96">
			<Tabs
				className="flex max-h-full grow flex-col"
				defaultSelectedKey={
					localStorage.getItem(openTabStorageKey) || undefined
				}
				onSelectionChange={(key: Key) =>
					localStorage.setItem(openTabStorageKey, key as string)
				}
			>
				<div className="flex items-center gap-1.5">
					<CloseDrawerButton onClick={onClose} />
					<TabList aria-label="Tabs" className="shrink-0 grow">
						<Tab id="history">{historyLabel}</Tab>
						<Tab id="todo">{todoLabel}</Tab>
					</TabList>
				</div>

				<TabPanel
					id="history"
					className="flex h-[calc(100%-1.5rem-26px)] max-h-full grow flex-col"
				>
					<HistoryLog entries={historyEntries} />
				</TabPanel>
				<TabPanel id="todo" className="flex max-h-full grow flex-col">
					<TodoWidget
						todos={todos}
						onAddTodo={(label) => dispatch(addTodo({ label }))}
						onToggleComplete={(id) => dispatch(toggleComplete(id))}
						onMoveTodoToTop={(id) => dispatch(moveTodoToTop(id))}
						onEdit={(id, label) => dispatch(editTodo({ id, label }))}
						onRemoveTodo={(id) => dispatch(removeTodo(id))}
						onRemoveCompleted={() => dispatch(removeCompleted())}
						onRemoveAll={() => {
							dispatch(removeAll());
						}}
					/>
				</TabPanel>
			</Tabs>
		</Panel>
	);
}
