import { useDispatch, useSelector } from 'react-redux';
import {
	selectBreakTimer,
	selectCurrentCycle,
	selectLongBreakTimer,
	selectWorkTimer,
	setBreakTimer,
	setLongBreakTimer,
	setWorkTimer,
	TimerCycles,
	TimerCyclesWithoutIdle,
} from '@entities/timer';

// ---

export default function useTimers() {
	const dispatch = useDispatch();
	const workTimer = useSelector(selectWorkTimer);
	const breakTimer = useSelector(selectBreakTimer);
	const longBreakTimer = useSelector(selectLongBreakTimer);
	const currentCycle = useSelector(selectCurrentCycle);

	const cycleToTime: Record<TimerCyclesWithoutIdle, number> = {
		[TimerCycles.WORK]: workTimer,
		[TimerCycles.BREAK]: breakTimer,
		[TimerCycles.LONG_BREAK]: longBreakTimer,
	};

	const cycleToChangeFunction: Record<
		TimerCyclesWithoutIdle,
		(n: number) => void
	> = {
		[TimerCycles.WORK]: (newTime: number) => dispatch(setWorkTimer(newTime)),
		[TimerCycles.BREAK]: (newTime: number) => dispatch(setBreakTimer(newTime)),
		[TimerCycles.LONG_BREAK]: (newTime: number) =>
			dispatch(setLongBreakTimer(newTime)),
	};

	return {
		workTimer,
		breakTimer,
		longBreakTimer,
		currentCycle,
		cycleToTime,
		cycleToChangeFunction,
	};
}
