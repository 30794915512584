import {
	Slider,
	Speaker,
	SpeakerLoud,
	SpeakerModerate,
	SpeakerDisabled,
} from '@shared/ui';

// ---

interface VolumeControlProps {
	onChange: (value: number) => void;
	volume: number;
}

const volumeToIcon = (volume: number) => {
	if (volume === 0) {
		return <SpeakerDisabled />;
	}

	if (volume > 75) {
		return <SpeakerLoud />;
	}

	if (volume > 50) {
		return <SpeakerModerate />;
	}

	return <Speaker />;
};

export default function VolumeControl({
	onChange,
	volume,
}: VolumeControlProps) {
	return (
		<div className="group/volume mx-auto w-full">
			<Slider
				label="Volume"
				onChange={onChange}
				defaultValue={30}
				value={volume}
				thumb={volumeToIcon(volume)}
			/>
		</div>
	);
}
