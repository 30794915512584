import { Middleware } from 'redux';

// ---

const DEFAULT_STATE_KEY = 'state';

// Action creator to save state to local storage
const saveStateToLocalStorage = (
	domainsWhitelist: string[],
	state: Record<string, unknown>
) => {
	const storeToSave = domainsWhitelist.reduce(
		(acc: Record<string, unknown>, domain: string) => {
			acc[domain] = state[domain];
			return acc;
		},
		{}
	);
	try {
		const serializedState = JSON.stringify(storeToSave);
		localStorage.setItem(DEFAULT_STATE_KEY, serializedState);
	} catch (error) {
		// Handle errors here
	}
};

// Middleware to save state to local storage on state change
export const localStorageMiddleware =
	(domainsWhitelist: string[]): Middleware =>
	(store) =>
	(next) =>
	(action) => {
		const result = next(action);
		saveStateToLocalStorage(domainsWhitelist, store.getState());
		return result;
	};

// Load state from local storage
export function loadStateFromLocalStorage(storeDomain: string) {
	try {
		const serializedState = localStorage.getItem(DEFAULT_STATE_KEY);
		if (serializedState === null) {
			return undefined;
		}
		return JSON.parse(serializedState)[storeDomain];
	} catch (error) {
		return undefined;
	}
}
