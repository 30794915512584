import { Locales, messages as sharedMessages } from '@shared/i18n';
import { messages as historyMessages } from '@entities/history';
import { messages as settingsMessages } from '@entities/setting';
import { messages as timerMessages } from '@entities/timer';
import { messages as todoMessages } from '@entities/todo';
import { messages as userMessages } from '@entities/user';
import { messages as mainPageMessages } from '@pages/main';

// ---

type MessageSet = Record<Locales, Record<string, string>>;

function composeMessages(...messageSets: MessageSet[]): MessageSet {
	return messageSets.reduce((acc, messageSet) => {
		const entries = Object.entries(messageSet) as [
			Locales,
			Record<string, string>,
		][];

		entries.forEach(([locale, messages]) => {
			acc[locale] = {
				...acc[locale],
				...messages,
			};
		});

		return acc;
	}, {} as MessageSet);
}

export default composeMessages(
	sharedMessages,
	mainPageMessages,
	historyMessages,
	settingsMessages,
	timerMessages,
	todoMessages,
	userMessages
);
