const minuteToModelRotation: Record<number, number> = {
	0: 0,
	1: 0.11,
	2: 0.21,
	3: 0.32,
	4: 0.42,
	5: 0.53,
	6: 0.64,
	7: 0.74,
	8: 0.84,
	9: 0.95,
	10: 1.06,
	11: 1.16,
	12: 1.26,
	13: 1.37,
	14: 1.47,
	15: 1.59,
	16: 1.68,
	17: 1.79,
	18: 1.89,
	19: 2,
	20: 2.11,
	21: 2.2,
	22: 2.31,
	23: 2.42,
	24: 2.52,
	25: 2.63,
	26: 2.73,
	27: 2.84,
	28: 2.94,
	29: 3.05,
	30: 3.16,
	31: 3.26,
	32: 3.36,
	33: 3.47,
	34: 3.57,
	35: 3.68,
	36: 3.78,
	37: 3.88,
	38: 3.99,
	39: 4.09,
	40: 4.2,
	41: 4.3,
	42: 4.41,
	43: 4.51,
	44: 4.62,
	45: 4.73,
	46: 4.83,
	47: 4.94,
	48: 5.04,
	49: 5.14,
	50: 5.25,
	51: 5.35,
	52: 5.46,
	53: 5.56,
	54: 5.67,
	55: 5.78,
	56: 5.87,
	57: 5.98,
	58: 6.08,
	59: 6.19,
	60: 6.29,
};

export default minuteToModelRotation;
