import { useDispatch, useSelector } from 'react-redux';

import {
	cycleToText,
	selectCurrentCycle,
	TimerCycles,
	stop,
	setCurrentCycle,
	TimerCyclesWithoutIdle,
} from '@entities/timer';
import { Pause, Button, Play } from '@shared/ui';
import { I18nMessage } from '@shared/i18n';

// ---

interface PlayPauseButtonProps {
	cycle: TimerCyclesWithoutIdle;
	onStart: () => void;
	onStop: () => void;
}

export default function PlayPauseButton({
	cycle,
	onStart,
	onStop,
}: PlayPauseButtonProps) {
	const dispatch = useDispatch();
	const currentCycle = useSelector(selectCurrentCycle);

	const handleClick = () => {
		if (currentCycle === TimerCycles.IDLE) {
			dispatch(setCurrentCycle(cycle));
			onStart();
		} else {
			dispatch(stop());
			onStop();
		}
	};

	return (
		<Button
			type="nostyles"
			round
			size="lg"
			textSizeOverride="text-2xl"
			onClick={handleClick}
		>
			{currentCycle === TimerCycles.IDLE ? <Play /> : <Pause />}
			<span className="sr-only">
				<I18nMessage
					id="timer.startCycle"
					values={{ cycle: cycleToText[cycle] }}
				/>
			</span>
		</Button>
	);
}
