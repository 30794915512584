import { useRef } from 'react';

// ---

interface HoldOptions {
	/**
	 * The time in milliseconds to wait before the hold is activated.
	 */
	delay?: number;
	/**
	 * The time in milliseconds to reduce the delay for subsequent calls.
	 */
	decrement?: number;

	/**
	 * The minimum delay between calls in milliseconds.
	 */
	minimumDelay?: number;
}

export function useHold<T = MouseEvent>(
	callback: (e: T) => void,
	options: HoldOptions
) {
	// const [timer, setTimer] = useState<number | null>(null);
	const repeatStarted = useRef<boolean | null>(false);
	const timerRef = useRef<number | null>(null);

	const handleMouseDown = (event: T) => {
		const { delay = 500, decrement = 100, minimumDelay = 50 } = options;

		function repeat(timeout: number) {
			const nextTimeout = Math.max(minimumDelay, timeout);

			timerRef.current = window.setTimeout(() => {
				repeatStarted.current = true;
				callback(event);
				repeat(timeout - decrement);
			}, nextTimeout);
		}

		repeat(delay);
	};

	const handleMouseUp = (event: T) => {
		if (!repeatStarted.current) {
			callback(event);
		}

		if (timerRef.current !== null) {
			window.clearTimeout(timerRef.current);
		}

		timerRef.current = null;
		repeatStarted.current = false;
	};

	return { handleMouseUp, handleMouseDown };
}
