import { TimerCycles, TimerCyclesWithoutIdle } from '../../model/store.ts';
import cycleToText from './cycle-to-text';

// ---

const cycleToColor: Record<TimerCyclesWithoutIdle, string> = {
	[TimerCycles.WORK]: 'text-primary-600 dark:text-primary-400',
	[TimerCycles.BREAK]: 'text-green-500 dark:text-green-300',
	[TimerCycles.LONG_BREAK]: 'text-blue-200 dark:text-blue-500',
};

interface CycleLabelProps {
	cycle: TimerCyclesWithoutIdle;
	colored?: boolean;
	className?: string;
}

export default function CycleLabel({
	cycle,
	colored,
	className,
}: CycleLabelProps) {
	return (
		<span className={`${colored ? cycleToColor[cycle] : ''} ${className}`}>
			{cycleToText[cycle]}
		</span>
	);
}
