import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

// ---

interface MessageProps {
	id: string;
	values?: Record<string, ReactNode>;
}

export default function Message({ id, values }: MessageProps) {
	return <FormattedMessage id={id} values={{ ...values }} />;
}

Message.defaultProps = {
	values: {},
};
