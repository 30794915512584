import { Clock } from 'three';
import { ThreeDEnv } from './types';

// ---

export interface ActionInfo {
	handler: (a: Action) => boolean;
	timeToExecute: number;
	type: string;
	onDone?: () => void;
	customData?: Record<string, unknown>;
}

export interface Action extends ActionInfo {
	clock: Clock;
}

function CreateAction(params: ActionInfo): Action {
	return {
		...params,
		clock: new Clock(),
	};
}

export class AnimationScheduler {
	private env3D: ThreeDEnv;
	private actions: Action[] = [];
	private isRunning = false;

	constructor(env3D: ThreeDEnv) {
		this.env3D = env3D;
		this.animate();
	}

	animate() {
		this.isRunning = true;

		const results = this.actions.map(({ handler, onDone, ...rest }) => {
			const result = handler({ handler, onDone, ...rest });

			if (result && onDone) {
				onDone();
				rest.clock.stop();
			}

			return !result;
		});
		this.actions = this.actions.filter((_, index) => results[index]);
		this.env3D.renderer.render(this.env3D.scene, this.env3D.camera);

		if (this.actions.length === 0) {
			this.isRunning = false;
			return;
		}

		requestAnimationFrame(() => {
			this.animate();
		});
	}

	addAction(actionParams: ActionInfo) {
		this.actions.push(CreateAction(actionParams));
		if (!this.isRunning) {
			this.animate();
		}
	}

	replaceAction(actionParams: ActionInfo) {
		let replaced = false;
		this.actions = this.actions.map(({ type, ...rest }) => {
			if (type === actionParams.type) {
				replaced = true;
				return CreateAction(actionParams);
			}
			return { type, ...rest };
		});

		if (!replaced) {
			this.actions.push(CreateAction(actionParams));
		}

		if (!this.isRunning) {
			this.animate();
		}
	}
}
