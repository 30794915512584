export default {
	'settings.settings': 'Налаштування',
	'settings.language': 'Мова',
	'settings.theme': 'Тема',
	'settings.longBreakFrequency': 'Частота разгрузки',
	'settings.enableAutoStart': 'Увімкнути автозапуск',
	'settings.enterFullscreenMode': 'Увійти в повноекранний режим',
	'settings.exitFullscreenMode': 'Вийти з повноекранного режиму',

	'settings.switchToLightTheme': 'Переключити світлу тему',
	'settings.switchToDarkTheme': 'Переключити темну тему',
	'settings.switchToSystemTheme': 'Переключити системну тему',

	'settings.openSettings': 'Відкрити налаштування',
};
