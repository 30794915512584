import { Heading as AriaHeading } from 'react-aria-components';

// ---

interface HeadingProps {
	children: string;
	className?: string;
	level: 1 | 2 | 3 | 4 | 5 | 6;
}

const levelToFontSize = {
	1: 'text-3xl',
	2: 'text-2xl',
	3: 'text-xl',
	4: 'text-lg',
	5: 'text-base',
	6: 'text-sm',
};

const levelToMargin = {
	1: 'mt-8',
	2: 'mt-6',
	3: 'mt-4',
	4: 'mt-3',
	5: 'mt-2',
	6: 'mt-1',
};

const levelToAdditionalStyles = {
	1: '',
	2: 'border-b-0',
	3: '',
	4: '',
	5: '',
	6: '',
};

export default function Heading({ children, className, level }: HeadingProps) {
	return (
		<AriaHeading
			level={level}
			className={`font-bold text-baseStrong-800 dark:text-baseWeak-900 ${className} ${levelToAdditionalStyles[level]} ${levelToMargin[level]} ${levelToFontSize[level]}`}
		>
			{children}
		</AriaHeading>
	);
}

Heading.defaultProps = {
	className: '',
	level: 1,
};
