export enum Locales {
	ENGLISH = 'en-US',
	RUSSIAN = 'ru-RU',
	UKRAINIAN = 'uk-UA',
}

const localeSignToLocale: Record<string, Locales> = {
	en: Locales.ENGLISH,
	ru: Locales.RUSSIAN,
	uk: Locales.UKRAINIAN,
};

export function getDefaultLocale(): Locales {
	const preferredLanguages = navigator.languages.map((lang) =>
		lang.includes('-') ? lang.split('-')[0] : lang
	);
	const firstSupportedLanguage = Object.entries(localeSignToLocale).find(
		([sign]) => {
			return preferredLanguages.includes(sign);
		}
	);

	return firstSupportedLanguage?.[1] || Locales.ENGLISH;
}
