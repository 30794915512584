import { TimerCycles } from '@entities/timer';
import { HistoryEntry } from '@entities/history';

// ---

export default function getNextCycle(
	history: HistoryEntry[],
	longBreakFrequency: number
) {
	const previousCycle = history[history.length - 1]?.cycle;

	if (previousCycle && previousCycle !== TimerCycles.WORK) {
		return TimerCycles.WORK;
	}

	const allBreaks = history.filter((entry) => entry.cycle !== TimerCycles.WORK);

	if (
		allBreaks
			.splice(-(longBreakFrequency - 1))
			.every((entry) => entry.cycle === TimerCycles.BREAK)
	) {
		return TimerCycles.LONG_BREAK;
	}

	return TimerCycles.BREAK;
}
