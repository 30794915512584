import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PlayPauseButton } from '@features/start-pause-timer';
import { VolumeControl } from '@features/sound';
import { ManualTimeEditField } from '@features/display-time-and-set-timer';
import { getMinutes, TimerCyclesWithoutIdle } from '@entities/timer';
import { selectVolumeLevel, setVolumeLevel } from '@entities/setting';
import { CheckList, Button } from '@shared/ui';
import { I18nMessage } from '@shared/i18n';

import AnimatedTime from './AnimatedTime';

// ---

interface MainControlsProps {
	isTodoOpen: boolean;
	selectedCycle: TimerCyclesWithoutIdle;
	currentTime: string;
	onStart: () => void;
	onStop: () => void;
	onToggleTodoList: () => void;
	onSetTime: (time: number) => void;
	disableTimeEdit: boolean;
}

export default function MainControls({
	isTodoOpen,
	selectedCycle,
	currentTime,
	onStart,
	onStop,
	onToggleTodoList,
	onSetTime,
	disableTimeEdit,
}: MainControlsProps) {
	const volume = useSelector(selectVolumeLevel);
	const [isEditingTime, setIsEditingTime] = useState<boolean>(false);
	const dispatch = useDispatch();

	const handleVolumeChange = (newVolume: number) => {
		dispatch(setVolumeLevel(newVolume));
	};

	const handleTimeEdit = (newTime: number) => {
		onSetTime(newTime);
		setIsEditingTime(false);
	};

	useEffect(() => {
		setIsEditingTime(false);
	}, [currentTime]);

	return (
		<div className="p-2">
			{isEditingTime ? (
				<ManualTimeEditField
					initialTime={getMinutes(currentTime)}
					onChange={handleTimeEdit}
					onCancel={() => setIsEditingTime(false)}
				/>
			) : (
				<div className="flex items-center justify-between gap-3">
					<PlayPauseButton
						cycle={selectedCycle}
						onStart={onStart}
						onStop={onStop}
					/>
					<div>
						<Button
							disabled={disableTimeEdit}
							type="nostyles"
							className="!p-0"
							onClick={() => {
								if (!disableTimeEdit) {
									setIsEditingTime(true);
								}
							}}
						>
							<span className="sr-only">
								<I18nMessage id="timer.editCurrentTime" />
							</span>
							<AnimatedTime time={currentTime} />
						</Button>
					</div>
					<Button
						size="lg"
						textSizeOverride="text-2xl"
						round
						type="nostyles"
						onClick={onToggleTodoList}
					>
						<CheckList />
						<span className="sr-only">
							<I18nMessage
								id={
									isTodoOpen
										? 'main.closeHistoryAndTodoDrawer'
										: 'main.openHistoryAndTodoDrawer'
								}
							/>
						</span>
					</Button>
				</div>
			)}
			<div className="p-2 touch:hidden">
				<VolumeControl onChange={handleVolumeChange} volume={volume} />
			</div>
		</div>
	);
}
