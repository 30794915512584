export function getDiffInMinutes(startedAt: number, endedAt: number) {
	return Math.round((endedAt - startedAt) / 60000);
}

export function createDateTimeFormatter(locale: string) {
	return new Intl.DateTimeFormat(locale, {
		hour: 'numeric',
		minute: 'numeric',
	});
}
