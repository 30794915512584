import { HistoryEntry } from '../model/model.ts';

// ---

const MAX_HISTORY_DAYS = 7;

export default function removeOutdatedEntries(history: HistoryEntry[]) {
	const now = new Date();

	return history.filter((entry) => {
		const dayDifference = Math.floor(
			(now.getTime() - new Date(entry.startedAt).getTime()) /
				(24 * 60 * 60 * 1000)
		);
		return dayDifference < MAX_HISTORY_DAYS;
	});
}
