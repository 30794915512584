import { ChangeEvent, ForwardedRef, forwardRef } from 'react';
import { Input as AriaInput } from 'react-aria-components';
import {
	defaultClassName,
	sizeToClassName,
	InputSizeIdentifier,
} from './classes';

// ---

type InputTypeIdentifier = 'text' | 'password' | 'email' | 'number';

interface InputProps {
	placeholder?: string;
	size?: InputSizeIdentifier;
	name?: string;
	defaultValue?: string;
	value?: string;
	required?: boolean;
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
	className?: string;
	maxLength?: number;
	type?: InputTypeIdentifier;
	id?: string;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
	(
		{
			id,
			size,
			type,
			name,
			defaultValue,
			value,
			required,
			onChange,
			placeholder,
			maxLength,
			className,
		}: InputProps,
		ref: ForwardedRef<HTMLInputElement>
	) => {
		return (
			<AriaInput
				id={id}
				value={value}
				ref={ref}
				onChange={onChange}
				name={name}
				defaultValue={defaultValue}
				required={required}
				type={type}
				placeholder={placeholder}
				maxLength={maxLength}
				className={`${defaultClassName} ${className} ${sizeToClassName[size!]}`}
			/>
		);
	}
);

Input.defaultProps = {
	size: 'md' as InputSizeIdentifier,
	placeholder: '',
	className: '',
	type: 'text' as InputTypeIdentifier,
	required: false,
	maxLength: 100,
};

export default Input;
