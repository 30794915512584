import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	replaceEntries,
	selectHistoryEntries,
	removeOutdatedEntries,
} from '@entities/history';

// ---

const STORAGE_REFRESH_INTERVAL = 5000;

// Removes outdated entries from the history log
export default function useHistoryLogRefreshes() {
	const historyEntries = useSelector(selectHistoryEntries);
	const dispatch = useDispatch();

	useEffect(() => {
		const interval = setInterval(() => {
			const filteredEntries = removeOutdatedEntries(historyEntries);

			if (filteredEntries.length !== historyEntries.length) {
				dispatch(replaceEntries(filteredEntries));
			}
		}, STORAGE_REFRESH_INTERVAL);

		return () => {
			clearInterval(interval);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [historyEntries]);
}
