import { useDispatch, useSelector } from 'react-redux';
import {
	selectBreakTimer,
	selectLongBreakTimer,
	selectWorkTimer,
	setCurrentCycle,
	stop as setIdle,
	TimerCycles,
	TimerCyclesWithoutIdle,
} from '@entities/timer';
import { useIntl } from 'react-intl';
import { endEntry, selectHistoryEntries, startEntry } from '@entities/history';
import { useEffect, useState } from 'react';

// ---

type EndReason = 'auto' | 'manual';

export default function useTickingTimer(
	onStart: (c: TimerCyclesWithoutIdle) => void,
	onEnd: (e: EndReason) => void
) {
	const workTimer = useSelector(selectWorkTimer);
	const breakTimer = useSelector(selectBreakTimer);
	const longBreakTimer = useSelector(selectLongBreakTimer);
	const intl = useIntl();
	const historyEntries = useSelector(selectHistoryEntries);
	const noTaskLabel = intl.formatMessage({ id: 'history.noTask' });
	const dispatch = useDispatch();
	const [tickingTime, setTickingTime] = useState('');
	const lastEntry = historyEntries[historyEntries.length - 1];
	const cycleToTime: Record<TimerCyclesWithoutIdle, number> = {
		[TimerCycles.WORK]: workTimer,
		[TimerCycles.BREAK]: breakTimer,
		[TimerCycles.LONG_BREAK]: longBreakTimer,
	};

	const start = (cycle: TimerCyclesWithoutIdle, label?: string) => {
		dispatch(
			startEntry({
				cycle: cycle,
				taskLabel: label ?? noTaskLabel,
			})
		);
		dispatch(setCurrentCycle(cycle));
		setTickingTime(`${cycleToTime[cycle]}:00`);
		onStart(cycle);
	};

	const tryToContinueCycle = () => {
		if (lastEntry && !lastEntry?.endedAt) {
			const lastEntryCycle = lastEntry.cycle;
			setTickingTime(
				`${cycleToTime[lastEntryCycle as TimerCyclesWithoutIdle]}:00`
			);
			dispatch(setCurrentCycle(lastEntryCycle));
			onStart(lastEntryCycle);
		}
	};

	const stop = (reason: EndReason = 'manual') => {
		dispatch(endEntry());
		dispatch(setIdle());
		setTickingTime('');
		onEnd(reason);
	};

	useEffect(() => {
		if (lastEntry && !lastEntry?.endedAt) {
			const interval = setInterval(() => {
				const windTime = cycleToTime[lastEntry.cycle as TimerCyclesWithoutIdle];
				const startTs = lastEntry.startedAt;
				const endTime = startTs + windTime * 60 * 1000;
				const diff = endTime - Date.now();
				const minutes = Math.floor(diff / 60000);
				const seconds = Math.round((diff % 60000) / 1000);

				if (diff <= 0) {
					stop('auto');
				} else {
					setTickingTime(
						`${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`
					);
				}
			}, 1000);
			return () => clearInterval(interval);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lastEntry]);

	useEffect(() => {
		tryToContinueCycle();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return { tickingTime, start, stop };
}
