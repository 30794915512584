export default {
	'timer.break': 'Перерыв',
	'timer.longBreak': 'Разгрузка',
	'timer.work': 'Работа',

	'timer.startCycle': 'Начать цикл {cycle}',

	'timer.editCurrentTime': 'Изменить текущее время',
	'timer.cancelTimeEdit': 'Отменить изменение времени',
	'timer.saveTime': 'Сохранить время',

	'timer.changeTimeTo': 'Изменить время на {time} минут',
};
