import { ReactNode } from 'react';

// ---

interface PanelProps {
	children: ReactNode;
	className?: string;
	noShadow?: boolean;
	embedded?: boolean;
}

export default function Panel({
	children,
	noShadow,
	className,
	embedded,
}: PanelProps) {
	const shadow = noShadow ? '' : 'shadow-lg';
	const embeddedStyle = embedded ? '' : 'rounded-lg p-4';

	return (
		<div
			className={`${embeddedStyle} ${shadow} ${className} bg-baseWeak-900 dark:bg-baseStrong-900`}
		>
			{children}
		</div>
	);
}
