import { ReactNode, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { HistoryEntry } from '@entities/history';
import { Locales } from '@shared/i18n';
import { getDiffInMinutes, createDateTimeFormatter } from '@shared/lib';

// ---

interface HistoryEntryProps {
	entry: HistoryEntry;
	locale: Locales;
	cycleLabel: ReactNode;
}

export default function HistoryEntryView({
	entry,
	locale,
	cycleLabel,
}: HistoryEntryProps) {
	const intl = useIntl();
	const minutesLabel = intl.formatMessage({ id: 'shared.minutes' });

	const dateTimeFormatter = useMemo(
		() => createDateTimeFormatter(locale),
		[locale]
	);

	return (
		<div className="group/history p-2">
			<div>
				{entry.taskLabel} · {cycleLabel}
			</div>
			<div>
				{getDiffInMinutes(entry.startedAt, entry.endedAt!)} {minutesLabel}{' '}
				<span className="hidden group-hover/history:inline">
					({dateTimeFormatter.format(new Date(entry.startedAt))} -{' '}
					{dateTimeFormatter.format(new Date(entry.endedAt!))})
				</span>
			</div>
		</div>
	);
}
