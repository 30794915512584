const CRUCIAL_ENV_VARS = [
	'VITE_RECAPTCHA_SITE_KEY',
	'VITE_SENTRY_DSN',
	'VITE_SENTRY_ORGANIZATION',
	'VITE_SENTRY_PROJECT',
	'VITE_FEEDBACK_FORM_URL',
];

// Check if all crucial environment variables are set to avoid situations
// where the app unexpectedly crashes or functions incorrectly because of missing variables
export function checkEnv() {
	const missingEnvVars = CRUCIAL_ENV_VARS.filter(
		(envVar) => !import.meta.env[envVar]
	);

	if (missingEnvVars.length > 0) {
		throw new Error(
			`Missing environment variables: ${missingEnvVars.join(', ')}. Please make sure to set them in your .env file.`
		);
	}
}
