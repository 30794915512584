import { Modal, Button, Panel, Text } from '@shared/ui';

// ---

interface StartNextCycleModalProps {
	isOpen: boolean;
	onCancel: () => void;
	onStart: () => void;
}

export default function StartNextCycleModal({
	isOpen,
	onCancel,
	onStart,
}: StartNextCycleModalProps) {
	return (
		<Modal isOpen={isOpen} onOpenChange={onCancel}>
			<Panel>
				<Text className="text-2xl font-bold">Start next cycle?</Text>
				<div className="mt-4 flex justify-center gap-3">
					<Button
						type="secondary"
						className="bg-baseWeak-800 text-baseWeak-100 rounded-lg px-4 py-2"
						onClick={onCancel}
					>
						Cancel
					</Button>
					<Button
						type="primary"
						className="bg-baseWeak-800 text-baseWeak-100 rounded-lg px-4 py-2"
						onClick={onStart}
					>
						Start
					</Button>
				</div>
			</Panel>
		</Modal>
	);
}
