import { useDispatch, useSelector } from 'react-redux';

import { selectTheme, setTheme, Theme } from '@entities/setting';
import { Moon, Sun, Computer, Button } from '@shared/ui';
import { I18nMessage } from '@shared/i18n';

// ---

export default function ThemeSwitcher() {
	const dispatch = useDispatch();
	const theme = useSelector(selectTheme);

	const currentThemeToNext = {
		[Theme.LIGHT]: Theme.SYSTEM,
		[Theme.DARK]: Theme.LIGHT,
		[Theme.SYSTEM]: Theme.DARK,
	};

	const handleThemeChange = () => {
		dispatch(setTheme(currentThemeToNext[theme]));
	};

	const styleToTheme = {
		[Theme.LIGHT]: 'rotate-90',
		[Theme.DARK]: '',
		[Theme.SYSTEM]: 'rotate-[-90deg]',
	};

	const themeToText = {
		[Theme.LIGHT]: <I18nMessage id="settings.switchToLightTheme" />,
		[Theme.DARK]: <I18nMessage id="settings.switchToDarkTheme" />,
		[Theme.SYSTEM]: <I18nMessage id="settings.switchToSystemTheme" />,
	};

	return (
		<div>
			<Button
				round
				size="lg"
				type="nostyles"
				className="relative overflow-hidden"
				textSizeOverride="text-2xl"
				onClick={handleThemeChange}
			>
				<div
					className={`absolute bottom-2.5 h-20 w-20 ${styleToTheme[theme]} duration-300`}
				>
					<div className="absolute left-0 top-[50%] translate-y-[-50%] rotate-90">
						<Computer />
					</div>
					<div className="absolute bottom-0 left-[50%] translate-x-[-50%]">
						<Moon />
					</div>
					<div className="absolute right-0 top-[50%] translate-y-[-50%]">
						<Sun />
					</div>
					<span className="sr-only">
						{themeToText[currentThemeToNext[theme]]}
					</span>
				</div>
			</Button>
		</div>
	);
}
