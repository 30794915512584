import messagesInEnglish from './en-US';
import messagesInUkrainian from './uk-UA';
import messagesInRussian from './ru-RU';
import { Locales } from '@shared/i18n';

// ---

export default {
	[Locales.ENGLISH]: messagesInEnglish,
	[Locales.UKRAINIAN]: messagesInUkrainian,
	[Locales.RUSSIAN]: messagesInRussian,
};
