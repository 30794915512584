import { v4 as uuidv4 } from 'uuid';

//---

export interface NewHistoryEntry {
	taskLabel?: string;
	cycle: number;
}

export interface HistoryEntry extends NewHistoryEntry {
	id: string;
	cycle: number;
	startedAt: number;
	endedAt?: number;
}

export function CreateHistoryEntry(
	taskLabel: string = '',
	cycle: number
): HistoryEntry {
	const now = Date.now();
	return {
		id: uuidv4(),
		taskLabel,
		startedAt: now,
		cycle,
	};
}

export function endHistoryEntry(entry: HistoryEntry): HistoryEntry {
	entry.endedAt = Date.now();
	return entry;
}
