import { TextArea as AriaTextArea } from 'react-aria-components';
import { ChangeEvent, ForwardedRef, forwardRef } from 'react';

import { defaultClassName, sizeToClassName } from './classes';

// ---

interface TextAreaProps {
	placeholder?: string;
	name?: string;
	value?: string;
	required?: boolean;
	onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
	className?: string;
	maxLength?: number;
	id?: string;
}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
	(
		{
			id,
			name,
			value,
			required,
			onChange,
			maxLength,
			placeholder,
			className,
		}: TextAreaProps,
		ref: ForwardedRef<HTMLTextAreaElement>
	) => {
		return (
			<AriaTextArea
				id={id}
				value={value}
				ref={ref}
				onChange={onChange}
				name={name}
				required={required}
				maxLength={maxLength}
				placeholder={placeholder}
				className={`${defaultClassName} ${sizeToClassName.md} ${className} min-h-32`}
			/>
		);
	}
);

export default TextArea;

TextArea.defaultProps = {
	maxLength: 500,
};
