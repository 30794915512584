export default {
	'timer.break': 'Перерва',
	'timer.longBreak': 'Релакс',
	'timer.work': 'Робота',

	'timer.startCycle': 'Почати цикл {cycle}',

	'timer.editCurrentTime': 'Змінити поточний час',
	'timer.cancelTimeEdit': 'Скасувати зміну часу',
	'timer.saveTime': 'Зберегти час',

	'timer.changeTimeTo': 'Змінити час на {time} хвилин',
};
