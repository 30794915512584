export default function Logo() {
	return (
		<svg
			width="3em"
			height="3em"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<defs />
			<ellipse
				style={{
					fill: 'rgb(172, 43, 43)',
					paintOrder: 'stroke',
					stroke: 'rgb(172, 43, 43)',
				}}
				cx="11.838"
				cy="13.517"
				rx="9.891"
				ry="9.891"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M 12.285 0.908 C 12.864 0.908 13.36 1.305 13.468 1.857 L 13.517 2.1 C 13.708 3.062 14.733 3.637 15.688 3.316 L 15.93 3.234 C 16.475 3.05 17.078 3.268 17.369 3.754 L 17.806 4.487 C 18.095 4.973 17.988 5.589 17.551 5.954 L 17.355 6.116 C 16.592 6.758 16.592 7.907 17.355 8.548 L 17.551 8.709 C 17.988 9.075 18.095 9.692 17.806 10.178 L 17.369 10.91 C 17.078 11.396 16.475 11.614 15.93 11.429 L 15.688 11.348 C 14.733 11.026 13.708 11.601 13.517 12.563 L 13.468 12.807 C 13.36 13.358 12.864 13.755 12.285 13.755 L 11.412 13.755 C 10.834 13.755 10.339 13.358 10.229 12.807 L 10.18 12.563 C 9.99 11.601 8.964 11.026 8.01 11.348 L 7.767 11.429 C 7.222 11.614 6.617 11.396 6.329 10.91 L 5.891 10.178 C 5.604 9.692 5.71 9.075 6.148 8.709 L 6.342 8.548 C 7.106 7.907 7.106 6.758 6.342 6.116 L 6.148 5.954 C 5.71 5.589 5.604 4.973 5.891 4.487 L 6.329 3.754 C 6.617 3.268 7.222 3.05 7.767 3.234 L 8.01 3.316 C 8.964 3.637 9.99 3.062 10.18 2.1 L 10.229 1.857 C 10.339 1.305 10.834 0.908 11.412 0.908 L 12.285 0.908 Z M 11.849 9.168 C 12.862 9.168 13.686 8.346 13.686 7.333 C 13.686 6.319 12.862 5.496 11.849 5.496 C 10.835 5.496 10.015 6.319 10.015 7.333 C 10.015 8.346 10.835 9.168 11.849 9.168 Z"
				style={{ fill: 'rgb(100, 29, 29)' }}
			/>
		</svg>
	);
}
