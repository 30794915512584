export default {
	'user.whatsYourName': "What's your name?",
	'user.niceToMeetYou': 'Nice to meet you, {name}!',
	'user.hello': 'Hello!',
	'user.username': 'Username',
	'user.defaultUsername': 'Suspicious Stranger',

	'user.fullName': 'Full name',
	'user.anonymous': 'Anonymous',
	'user.email': 'Email',
	'user.yourEmail': 'Your Email',
	'user.feedback': 'Feedback',
	'user.yourFeedback': 'Your feedback',
	'user.chooseTopic': 'Choose topic',

	'user.validationInvalidEmail': 'Please enter a valid email address',
	'user.validationFillOutThisField': 'Please fill out this field',
	'user.feedbackSuccess': 'Feedback sent successfully!',
	'user.feedbackFail': 'Failed to send feedback. Please try again later.',
	'user.feedbackSubmitting': 'Submitting...',

	'user.sendFeedback': 'Send feedback',
	'user.feedbackTopicGeneral': 'General feedback',
	'user.feedbackTopicBug': 'Bug report',
	'user.feedbackTopicComplaint': 'Complaint',
	'user.feedbackTopicFeature': 'Feature request',
	'user.feedbackTopicContact': 'Contact the developer',
	'user.feedbackTopicOther': 'Other',
};
