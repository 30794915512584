import { ReactNode } from 'react';

// ---

interface ListProps {
	children: ReactNode;
	className?: string;
	elementType?: 'ul' | 'ol';
}

interface ListItemProps {
	children: ReactNode;
}

const sharedClassName =
	'list-outside mt-2 marker:text-baseStrong-400 dark:marker:text-[#bbb] text-baseStrong-900 dark:text-baseWeak-900';

export function ListItem({ children }: ListItemProps) {
	return <li className="ml-6">{children}</li>;
}

export default function List({ children, className, elementType }: ListProps) {
	return elementType === 'ul' ? (
		<ul className={`list-disc ${sharedClassName} ${className}`}>{children}</ul>
	) : (
		<ol className={`list-decimal ${sharedClassName} ${className}`}>
			{children}
		</ol>
	);
}

List.defaultProps = {
	className: '',
	elementType: 'ul',
};
