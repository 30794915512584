import { Button, QuestionMark } from '@shared/ui';

// ---

interface HowToUseTriggerProps {
	onClick: () => void;
}

export default function HowToUseTrigger({ onClick }: HowToUseTriggerProps) {
	return (
		<div>
			<Button
				textSizeOverride="text-2xl"
				round
				type="nostyles"
				size="lg"
				onClick={onClick}
			>
				<QuestionMark />
			</Button>
		</div>
	);
}
