import TodoControls from '../TodoControls';
import TodoList from '../TodoList';
import { Todo } from '@entities/todo/model/model.ts';

// ---

interface TodoWidgetProps {
	todos: Todo[];
	onAddTodo: (label: string) => void;
	onToggleComplete: (id: string) => void;
	onMoveTodoToTop: (id: string) => void;
	onEdit: (id: string, label: string) => void;
	onRemoveTodo: (id: string) => void;
	onRemoveCompleted: () => void;
	onRemoveAll: () => void;
}

export default function TodoWidget({
	todos,
	onAddTodo,
	onToggleComplete,
	onEdit,
	onRemoveTodo,
	onRemoveCompleted,
	onRemoveAll,
	onMoveTodoToTop,
}: TodoWidgetProps) {
	return (
		<div className="flex max-h-full grow flex-col">
			<div className="pb-4">
				<TodoControls
					onAddTodo={onAddTodo}
					onRemoveAll={onRemoveAll}
					onRemoveCompleted={onRemoveCompleted}
				/>
			</div>
			<TodoList
				todos={todos}
				onMoveTodoToTop={onMoveTodoToTop}
				onToggleComplete={onToggleComplete}
				onEdit={onEdit}
				onDelete={onRemoveTodo}
			/>
		</div>
	);
}
