import { ReactNode } from 'react';
import { Label as AriaLabel } from 'react-aria-components';

// ---

interface LabelProps {
	children: ReactNode;
	htmlFor: string;
	className?: string;
}

export default function Label({ children, htmlFor, className }: LabelProps) {
	return (
		<AriaLabel
			htmlFor={htmlFor}
			className={`block text-baseStrong-800 dark:text-baseWeak-900 ${className}`}
		>
			{children}
		</AriaLabel>
	);
}
