import { ReactNode } from 'react';
import { Text as ArialText } from 'react-aria-components';

// ---

interface TextProps {
	children: ReactNode;
	className?: string;
	noMargin?: boolean;
	elementType?: 'p' | 'span' | 'div';
}

export default function Text({
	children,
	className,
	noMargin,
	elementType,
}: TextProps) {
	return (
		<ArialText
			elementType={elementType}
			className={`font-verdana text-baseStrong-800 dark:text-baseWeak-900 ${elementType === 'p' && !noMargin ? 'mt-2' : ''} ${className}`}
		>
			{children}
		</ArialText>
	);
}

Text.defaultProps = {
	className: '',
	elementType: 'p',
	noMargin: false,
};
