import { useSelector } from 'react-redux';

import { List, Text, Heading, ListItem } from '@shared/ui';
import { selectLanguage } from '@entities/setting';
import { Locales } from '@shared/i18n';

// ---

function EnglishArticle() {
	return (
		<article>
			<Heading level={2}>What is it all about?</Heading>
			<Heading level={3}>A Brief History of the Pomodoro Technique</Heading>
			<Text>
				The Pomodoro Technique was developed in the late 1980s by Francesco
				Cirillo. Named after the tomato-shaped kitchen timer Cirillo used during
				his university days in Italy, this method has gained popularity
				worldwide for its simplicity and effectiveness. By breaking work into
				focused intervals, traditionally 25 minutes, followed by short breaks,
				this technique boosts concentration and reduces mental fatigue, making
				it a favorite among students and professionals alike.
			</Text>
			<Heading level={3}>Understanding the Pomodoro Technique</Heading>
			<Text>
				At its core, the Pomodoro Technique is about managing time in short,
				productive bursts. Here’s how you can easily integrate this method into
				your daily routine:
			</Text>
			<List elementType="ol">
				<ListItem>
					<b>Choose a Task:</b> Decide on the task you need to accomplish.
				</ListItem>
				<ListItem>
					<b>Set the Timer:</b> Use this timer to count down 25 minutes of
					focused work.
				</ListItem>
				<ListItem>
					<b>Work Intensively:</b> Eliminate all distractions and focus solely
					on the task.
				</ListItem>
				<ListItem>
					<b>Take a Short Break:</b> Once the timer rings, take a 5-minute break
					to relax your mind.
				</ListItem>
				<ListItem>
					<b>Repeat:</b> Complete four Pomodoros, then take a longer break of
					15-30 minutes.
				</ListItem>
			</List>
			<Heading level={3} className="text-xl">
				Specifics of This App
			</Heading>
			<Text>
				This application is designed to provide you with a interesting and
				efficient tool for task management. It has a bare minimum of features to
				allow you conveniently work on your tasks:
			</Text>
			<List>
				<ListItem>
					You can set the timer for each cycle to any duration you prefer by
					winding the 3D timer.
				</ListItem>
				<ListItem>
					You can change the timer behavior a bit and other things in the
					settings.
				</ListItem>
				<ListItem>
					This timer also has a built-in to-do list feature to help you keep
					track of your tasks.
				</ListItem>
				<ListItem>
					To see what you've completed, check the history tab.
				</ListItem>
			</List>
			<Heading level={3} className="text-xl">
				About the Intention
			</Heading>
			<Text>
				This application was created by a single developer with the intention of
				playing around 3D models in web development. The idea was to integrate
				3D model to a common web application and make it interactive, and thus
				show that outside of just aesthetics, 3D models can be used as part of
				controls and other crucial parts in modern applications. The Pomodoro
				timer was chosen as the subject because the author uses it daily and
				would not mind making a contribution to the popularization of this
				technique and also giving users an interesting and smooth user
				experience.
			</Text>
		</article>
	);
}

function RussianArticle() {
	return (
		<article>
			<Heading level={2} className="text-2xl">
				О чём это всё?
			</Heading>
			<Heading level={3} className="text-xl">
				Краткая история техники Помодоро
			</Heading>
			<Text>
				Техника Помодоро была разработана в конце 1980-х годов Франческо
				Чирилло. Названная в честь кухонного таймера в форме помидора, который
				Чирилло использовал во время учёбы в университете в Италии, этот метод
				завоевал популярность по всему миру благодаря своей простоте и
				эффективности. Разбивая работу на фокусированные интервалы, традиционно
				по 25 минут, с последующими короткими перерывами, эта техника повышает
				концентрацию и снижает умственное утомление, что делает её любимой как
				среди студентов, так и среди профессионалов.
			</Text>
			<Heading level={3} className="text-xl">
				Понимание техники Помодоро
			</Heading>
			<Text>
				В основе техники Помодоро лежит управление временем в коротких,
				продуктивных всплесках. Вот как вы можете легко интегрировать этот метод
				в свою повседневную учебу и работу:
			</Text>
			<List elementType="ol" className="list-inside list-decimal">
				<ListItem>
					<b>Выберите задачу:</b> Решите, какую задачу вы хотите выполнить.
				</ListItem>
				<ListItem>
					<b>Установите таймер:</b> Используйте этот таймер, чтобы отсчитать
					(например) 25 минут фокусированной работы.
				</ListItem>
				<ListItem>
					<b>Работайте интенсивно:</b> Исключите все отвлечения и
					сосредоточьтесь только на задаче.
				</ListItem>
				<ListItem>
					<b>Сделайте короткий перерыв:</b> Как только таймер зазвучит, сделайте
					5-минутный перерыв, чтобы отдохнуть.
				</ListItem>
				<ListItem>
					<b>Повторите:</b> Завершите четыре помидора, затем сделайте более
					длительный перерыв от 15 до 30 минут.
				</ListItem>
			</List>
			<Heading level={3} className="text-xl">
				Особенности этого приложения
			</Heading>
			<Text>
				Это приложение разработано для предоставления вам интересного и
				эффективного инструмента для управления задачами. Оно имеет минимум
				функций, чтобы вы могли удобно работать над своими задачами:
			</Text>
			<List>
				<ListItem>
					Вы можете установить таймер для каждого цикла на любую
					продолжительность, которую предпочитаете, повернув 3D-таймер.
				</ListItem>
				<ListItem>
					Вы можете немного изменить поведение таймера и другие параметры в
					настройках.
				</ListItem>
				<ListItem>
					Этот таймер также имеет встроенную функцию списка дел, чтобы помочь
					вам отслеживать свои задачи.
				</ListItem>
				<ListItem>
					Чтобы увидеть, что вы выполнили, проверьте вкладку истории.
				</ListItem>
			</List>
			<Heading level={3} className="text-xl">
				О намерении
			</Heading>
			<Text>
				Это приложение было создано одним разработчиком с намерением получить
				опыт работы с 3D-моделями в веб-разработке. Идея заключалась в
				интеграции интерактивной 3D-модели в обычное веб-приложение, чтобы
				показать, что вне эстетики 3D-модели могут использоваться как часть
				элементов управления и других важных частей в современных приложениях.
				Таймер Помодоро был выбран в качестве объекта, потому что автор
				использует его ежедневно и не против внести свой вклад в популяризацию
				этой техники, а также предоставить пользователям интересный и плавный
				пользовательский опыт.
			</Text>
		</article>
	);
}

function UkrainianArticle() {
	return (
		<article>
			<Heading level={2} className="text-2xl">
				Про що все це?
			</Heading>
			<Heading level={3} className="text-xl">
				Коротка історія техніки Помодоро
			</Heading>
			<Text>
				Техніка Помодоро була розроблена наприкінці 1980-х років Франческо
				Чірилло. Названа на честь кухонного таймера у формі помідора, який
				Чірилло використовував під час навчання в університеті в Італії, цей
				метод завоював популярність по всьому світу завдяки своїй простоті та
				ефективності. Розбиваючи роботу на фокусовані інтервали, традиційно по
				25 хвилин, з наступними короткими перервами, ця техніка підвищує
				концентрацію та знижує розумове виснаження, що робить її улюбленою як
				серед студентів, так і серед професіоналів.
			</Text>
			<Heading level={3} className="text-xl">
				Розуміння техніки Помодоро
			</Heading>
			<Text>
				В основі техніки Помодоро лежить управління часом в коротких,
				продуктивних в сплесках. Ось як ви можете легко інтегрувати цей метод у
				своє щоденне навчання та роботу:
			</Text>
			<List elementType="ol" className="list-inside list-decimal">
				<ListItem>
					<b>Виберіть завдання:</b> Вирішіть, яке завдання ви хочете виконати.
				</ListItem>
				<ListItem>
					<b>Встановіть таймер:</b> Використовуйте цей таймер, щоб відлічити 25
					хвилин фокусованої роботи.
				</ListItem>
				<ListItem>
					<b>Працюйте інтенсивно:</b> Виключіть всі відволікання та сфокусуйтеся
					лише на завданні.
				</ListItem>
				<ListItem>
					<b>Зробіть коротку перерву:</b> Як тільки таймер зазвучить, зробіть
					5-хвилинну перерву, щоб відпочити.
				</ListItem>
				<ListItem>
					<b>Повторіть:</b> Завершіть чотири помідори, потім зробіть більш
					тривалу перерву від 15 до 30 хвилин.
				</ListItem>
			</List>
			<Heading level={3} className="text-xl">
				Особливості цього додатка
			</Heading>
			<Text>
				Цей додаток розроблено для надання вам цікавого та ефективного
				інструмента для управління завданнями. Він має необхідний мінімум
				функцій, щоб дозволити вам зручно працювали над своїми завданнями:
			</Text>
			<List>
				<ListItem>
					Ви можете встановити таймер для кожного циклу на будь-яку тривалість,
					яку ви вважаєте за краще, повернувши 3D-таймер.
				</ListItem>
				<ListItem>
					Ви можете трохи змінити поведінку таймера та інші параметри в
					налаштуваннях.
				</ListItem>
				<ListItem>
					Цей таймер також має вбудовану функцію списку справ, щоб допомогти вам
					відстежувати свої завдання.
				</ListItem>
				<ListItem>
					Щоб побачити, що ви вже виконали, перевірте вкладку історії.
				</ListItem>
			</List>
			<Heading level={3} className="text-xl">
				Про намір
			</Heading>
			<Text>
				Цей додаток було створено одним розробником з наміром отримати досвід у
				роботі з 3D-моделями в веб-розробці. Ідея полягала в інтеграції
				інтерактивної 3D-моделі в звичайний веб-додаток, щоб показати, що поза
				естетикою 3D-моделі можуть використовуватися як частина елементів
				управління та інших важливих частин у сучасних додатках. Таймер Помодоро
				було обрано в якості об'єкта, оскільки автор використовує його щодня і
				не проти внести свій внесок у популяризацію цієї техніки, а також надати
				користувачам цікавий та плавний користувацький досвід.
			</Text>
		</article>
	);
}

const localeToArticle = {
	[Locales.ENGLISH]: EnglishArticle,
	[Locales.RUSSIAN]: RussianArticle,
	[Locales.UKRAINIAN]: UkrainianArticle,
};

export default function HowToUseArticle() {
	const currentLanguage = useSelector(selectLanguage);

	const ArticleToRender = localeToArticle[currentLanguage];

	return (
		<div>
			<ArticleToRender />
		</div>
	);
}
