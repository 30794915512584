import { FormEvent, useRef, useId } from 'react';
import { Form } from 'react-aria-components';

import { Button, Plus, ThreeDots, PopoverMenu, Input, Label } from '@shared/ui';
import { I18nMessage } from '@shared/i18n';

// ---

interface TodoControllerProps {
	onAddTodo: (label: string) => void;
	onRemoveCompleted: () => void;
	onRemoveAll: () => void;
}
export default function TodoControls({
	onAddTodo,
	onRemoveCompleted,
	onRemoveAll,
}: TodoControllerProps) {
	const formRef = useRef<HTMLFormElement>(null);
	const inputRef = useRef<HTMLInputElement>(null);
	const inputId = useId();

	const handleAddTodo = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		const label = data.get('label') as string | undefined;

		if (typeof label === 'string' && label.trim() !== '') {
			onAddTodo(label);
			formRef.current?.reset();
			inputRef.current?.focus();
		}
	};

	return (
		<div className="flex gap-3">
			<Form ref={formRef} onSubmit={handleAddTodo} className="flex grow gap-2">
				<Label htmlFor={inputId} className="sr-only">
					<I18nMessage id="todo.newTodo" />
				</Label>
				<Input
					id={inputId}
					ref={inputRef}
					name="label"
					className="w-auto grow"
					type="text"
				/>
				<Button
					htmlType="submit"
					round
					className="shrink-0"
					type="primary"
					size="md"
				>
					<span className="sr-only">
						<I18nMessage id="todo.addTodo" />
					</span>
					<Plus />
				</Button>
			</Form>

			<div className="flex gap-2">
				<PopoverMenu
					Trigger={() => (
						<Button round type="secondary" size="md">
							<span className="sr-only">
								<I18nMessage id="todo.moreActions" />
							</span>
							<ThreeDots />
						</Button>
					)}
					items={[
						{
							id: 'remove-completed',
							label: <I18nMessage id="todo.deleteCompleted" />,
						},
						{ id: 'remove-all', label: <I18nMessage id="todo.deleteAll" /> },
					]}
					onAction={(id) => {
						if (id === 'remove-completed') {
							onRemoveCompleted();
						} else if (id === 'remove-all') {
							onRemoveAll();
						}
					}}
				/>
			</div>
		</div>
	);
}
