import { useEffect, useRef } from 'react';

import { TimerCycles } from '@entities/timer';
import { isTouch } from '@shared/lib';

// ---

const tickingSound = new URL(
	'@shared/assets/sounds/ticking.mp3',
	import.meta.url
);

const windingUpSound = new URL(
	'@shared/assets/sounds/winding-up.mp3',
	import.meta.url
);

const bellRingSound = new URL(
	'@shared/assets/sounds/bell-ring.mp3',
	import.meta.url
);

interface SoundPlayerProps {
	volumeLevel: number; // 0 to 1
	isMuted: boolean;
	windTime: number;
	currentCycle: TimerCycles;
	playEndSound: boolean;
}

export default function SoundPlayer({
	volumeLevel,
	isMuted,
	windTime,
	playEndSound,
	currentCycle,
}: SoundPlayerProps) {
	const isFirstRender = useRef(true);

	const tickingAudioRef = useRef<HTMLAudioElement>(null);
	const windingUpAudioRef = useRef<HTMLAudioElement>(null);
	const endRingingAudioRef = useRef<HTMLAudioElement>(null);

	useEffect(() => {
		const audio = tickingAudioRef.current;
		if (audio) {
			audio.muted = isMuted;
			if (currentCycle === TimerCycles.WORK && !isTouch(window)) {
				audio.currentTime = 0;
				audio.play();
			} else {
				audio.pause();
			}
		}
	}, [isMuted, currentCycle]);

	useEffect(() => {
		const windingUpAudio = windingUpAudioRef.current;
		function playSound() {
			if (!windingUpAudio) return;

			// Create a new instance of the audio element
			const newSound: HTMLAudioElement = windingUpAudio.cloneNode(
				true
			) as HTMLAudioElement;
			if (newSound) {
				newSound.volume = volumeLevel;
				newSound.muted = isMuted;
				newSound.currentTime = 0; // Rewind sound to start

				if (!isFirstRender.current) {
					newSound.play();
				}
			}
		}
		if (windingUpAudio) {
			windingUpAudio.muted = isMuted;
			if (windTime > 0) {
				playSound();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [windTime]);

	useEffect(() => {
		const endRingingAudio = endRingingAudioRef.current;
		if (endRingingAudio) {
			endRingingAudio.volume = 1;
			endRingingAudio.muted = isMuted;
			if (playEndSound) {
				endRingingAudio.currentTime = 0;
				endRingingAudio.play();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [playEndSound]);

	useEffect(() => {
		const tickingAudio = tickingAudioRef.current;
		const windingUpAudio = windingUpAudioRef.current;

		if (tickingAudio) {
			tickingAudio.volume = isTouch(window) ? 0 : volumeLevel;
		}

		if (windingUpAudio) {
			windingUpAudio.volume = volumeLevel;
		}
	}, [volumeLevel]);

	isFirstRender.current = false;

	return (
		<>
			<audio ref={tickingAudioRef} loop>
				<source src={tickingSound.href} type="audio/mp3" />
			</audio>
			<audio ref={endRingingAudioRef}>
				<source src={bellRingSound.href} type="audio/mp3" />
			</audio>
			<audio ref={windingUpAudioRef}>
				<source src={windingUpSound.href} type="audio/mp3" />
			</audio>
		</>
	);
}
