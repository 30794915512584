import { ReactNode, useEffect, useRef, useState, TransitionEvent } from 'react';

// ---

interface MarqueeProps {
	children: ReactNode;
	speed?: number;
}

export default function Marquee({ children, speed }: MarqueeProps) {
	const containerRef = useRef<HTMLDivElement>(null);
	const textRef = useRef<HTMLSpanElement>(null);
	const [isOverflowing, setIsOverflowing] = useState(false);
	const [toEnd, setToEnd] = useState(false);
	const [widthDiff, setWidthDiff] = useState(0);

	useEffect(() => {
		if (containerRef.current && textRef.current) {
			const containerWidth = containerRef.current.offsetWidth;
			const textWidth = textRef.current.scrollWidth;
			if (textWidth > containerWidth) {
				setWidthDiff(textWidth - containerWidth);
				setIsOverflowing(true);
				setToEnd(true);
			} else {
				setIsOverflowing(false);
				setWidthDiff(0);
			}
		}
	}, [children]);

	const styles = isOverflowing
		? {
				transform: `translateX(${toEnd ? -widthDiff : 0}px)`,
				transitionProperty: 'transform',
				transitionDuration: `${speed || 8}s`,
				transitionTimingFunction: 'linear',
			}
		: {};

	return (
		<div
			ref={containerRef}
			className="box-border w-full overflow-hidden whitespace-nowrap text-baseStrong-800 dark:text-baseWeak-900"
		>
			<span
				ref={textRef}
				className={`inline-block px-4`}
				style={styles}
				onTransitionEnd={(event: TransitionEvent) => {
					if (event.nativeEvent.propertyName === 'transform') {
						const nextDirection = !toEnd;
						setToEnd(nextDirection);
					}
				}}
			>
				{children}
			</span>
		</div>
	);
}
