import {
	Key,
	Tab as AriaTab,
	TabList as AriaTabList,
	TabPanel as AriaTabPanel,
	Tabs as AriaTabs,
} from 'react-aria-components';
import { ReactNode } from 'react';

// ---

interface TabsProps {
	children: ReactNode;
	className?: string;
	defaultSelectedKey?: string;
	onSelectionChange?: (key: Key) => void;
}

export function Tabs({
	defaultSelectedKey,
	children,
	className,
	onSelectionChange,
}: TabsProps) {
	return (
		<AriaTabs
			defaultSelectedKey={defaultSelectedKey}
			onSelectionChange={onSelectionChange}
			className={className}
		>
			{children}
		</AriaTabs>
	);
}

interface TabListProps {
	children: ReactNode;
	ariaLabel?: string;
	className?: string;
}

export function TabList({ children, className, ariaLabel }: TabListProps) {
	return (
		<AriaTabList
			aria-label={ariaLabel}
			className={`flex select-none justify-center gap-1 rounded-3xl bg-gray-200 p-1 dark:bg-baseStrong-700 ${className}`}
		>
			{children}
		</AriaTabList>
	);
}

interface TabProps {
	children: ReactNode;
	id: string;
}

export function Tab({ children, id }: TabProps) {
	return (
		<AriaTab
			className="cursor-pointer rounded-3xl border px-4 py-2 font-semibold outline-none transition-colors duration-200 hover:bg-[rgba(255,255,255,0.3)] data-[selected=true]:bg-baseWeak-900 data-[selected=true]:text-primary-700 data-[selected=true]:shadow-sm dark:border-baseStrong-700 dark:bg-baseStrong-700 dark:hover:bg-baseStrong-800 dark:data-[selected=true]:bg-baseStrong-900"
			id={id}
			key={id}
		>
			{children}
		</AriaTab>
	);
}

interface TabPanelProps {
	children: ReactNode;
	id: string;
	className?: string;
}

export function TabPanel({ children, className, id }: TabPanelProps) {
	return (
		<AriaTabPanel className={`pt-4 ${className}`} id={id}>
			{children}
		</AriaTabPanel>
	);
}
