import { createSlice } from '@reduxjs/toolkit';
import { CreateHistoryEntry, HistoryEntry, NewHistoryEntry } from './model.ts';
import { loadStateFromLocalStorage } from '@shared/lib';

// ---

const initialState: {
	entries: HistoryEntry[];
} = {
	entries: [],
};

const historySlice = createSlice({
	name: 'history',
	initialState: loadStateFromLocalStorage('history') || initialState,
	reducers: {
		startEntry(state, action: { payload: NewHistoryEntry }) {
			state.entries.push(
				CreateHistoryEntry(action.payload.taskLabel, action.payload.cycle)
			);
		},
		endEntry(state) {
			const entry = state.entries[state.entries.length - 1];
			if (entry) {
				entry.endedAt = Date.now();
			}
		},
		removeEntry(state, action: { payload: string }) {
			state.entries = state.entries.filter(
				(entry: HistoryEntry) => entry.id !== action.payload
			);
		},
		replaceEntries(state, action: { payload: HistoryEntry[] }) {
			state.entries = action.payload;
		},
	},
});

export const { startEntry, endEntry, removeEntry, replaceEntries } =
	historySlice.actions;

export const selectHistoryEntries = (state: { history: typeof initialState }) =>
	state.history.entries;

export const historyReducer = historySlice.reducer;
