import { ReactNode } from 'react';

import { ChevronRight, Button } from '@shared/ui';
import { I18nMessage } from '@shared/i18n';

// ---

interface DrawerProps {
	children: ReactNode;
	show: boolean;
}

export default function Drawer({ children, show }: DrawerProps) {
	return (
		<aside
			className={`${show ? 'right-0' : 'right-[-100%]'} fixed bottom-2 top-2 z-50 flex duration-500`}
			/* Should not be focusable while outside the viewport */
			tabIndex={show ? undefined : -1}
		>
			{children}
		</aside>
	);
}

interface CloseDrawerButtonProps {
	onClick: () => void;
}

export function CloseDrawerButton({ onClick }: CloseDrawerButtonProps) {
	return (
		<Button size="lg" textSizeOverride="text-2xl" round onClick={onClick}>
			<span className="sr-only">
				<I18nMessage id={'shared.closeDrawer'} />
			</span>
			<ChevronRight />
		</Button>
	);
}
