import { TimerCycles, TimerCyclesWithoutIdle } from '@entities/timer';
import { ReactNode } from 'react';
import { I18nMessage } from '@shared/i18n';

const cycleToText: Record<TimerCyclesWithoutIdle, ReactNode> = {
	[TimerCycles.WORK]: <I18nMessage id="timer.work" />,
	[TimerCycles.BREAK]: <I18nMessage id="timer.break" />,
	[TimerCycles.LONG_BREAK]: <I18nMessage id="timer.longBreak" />,
};

export default cycleToText;
