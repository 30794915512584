import { createSlice } from '@reduxjs/toolkit';
import { loadStateFromLocalStorage } from '@shared/lib';
import { Locales, getDefaultLocale } from '@shared/i18n';

// ---

export enum Theme {
	SYSTEM = 'system',
	LIGHT = 'light',
	DARK = 'dark',
}

export const initialState = {
	theme: Theme.SYSTEM,
	isFullscreen: false,
	volumeLevel: 50,
	autostart: false,
	language: getDefaultLocale(),
	longBreakEvery: 4,
};

interface SettingsPartOfGlobalState {
	settings: typeof initialState;
}

const settingsSlice = createSlice({
	name: 'settings',
	initialState: loadStateFromLocalStorage('settings') || initialState,
	reducers: {
		setTheme(state, action: { payload: Theme }) {
			state.theme = action.payload;
		},
		setFullscreen(state, action: { payload: boolean }) {
			state.isFullscreen = action.payload;
		},
		setVolumeLevel(state, action: { payload: number }) {
			state.volumeLevel = action.payload;
		},
		setAutostart(state, action: { payload: boolean }) {
			state.autostart = action.payload;
		},
		setLanguage(state, action: { payload: Locales }) {
			state.language = action.payload;
		},
		setLongBreakEvery(state, action: { payload: number }) {
			state.longBreakEvery = action.payload;
		},
	},
});

export const {
	setTheme,
	setFullscreen,
	setVolumeLevel,
	setAutostart,
	setLanguage,
	setLongBreakEvery,
} = settingsSlice.actions;

export const selectAutostart = (state: SettingsPartOfGlobalState) =>
	state.settings.autostart;

export const selectVolumeLevel = (state: SettingsPartOfGlobalState) =>
	state.settings.volumeLevel;

export const selectIsFullscreen = (state: SettingsPartOfGlobalState) =>
	state.settings.isFullscreen;

export const selectTheme = (state: SettingsPartOfGlobalState) =>
	state.settings.theme;

export const selectLanguage = (state: SettingsPartOfGlobalState) =>
	state.settings.language;

export const selectLongBreakEvery = (state: SettingsPartOfGlobalState) =>
	state.settings.longBreakEvery;

export const settingsReducer = settingsSlice.reducer;
