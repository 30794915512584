import { FieldError as AriaFieldError } from 'react-aria-components';

// ---

interface FieldErrorProps {
	className?: string;
}

export default function FieldError({ className }: FieldErrorProps) {
	return <AriaFieldError className={`text-rose-800 ${className}`} />;
}

FieldError.defaultProps = {
	className: '',
};
