import { Drawer } from '@shared/ui';
import HowToUseDrawer from './HowToUseDrawer';
import HistoryTodoDrawer from './HistoryTodoDrawer';
import SettingsDrawer from './SettingsDrawer';
import DefinedDrawers from './defined-drawers.ts';

// ---

interface DrawersProps {
	drawerToShow: DefinedDrawers | null;
	onDrawerClose: (arg: DefinedDrawers) => void;
}

export default function Drawers({ drawerToShow, onDrawerClose }: DrawersProps) {
	return (
		<>
			<Drawer show={drawerToShow === DefinedDrawers.HowToUse}>
				<HowToUseDrawer
					onClose={() => onDrawerClose(DefinedDrawers.HowToUse)}
				/>
			</Drawer>

			<Drawer show={drawerToShow === DefinedDrawers.HistoryTodo}>
				<HistoryTodoDrawer
					onClose={() => onDrawerClose(DefinedDrawers.HistoryTodo)}
				/>
			</Drawer>

			<Drawer show={drawerToShow === DefinedDrawers.Settings}>
				<SettingsDrawer
					onClose={() => onDrawerClose(DefinedDrawers.Settings)}
				/>
			</Drawer>
		</>
	);
}
