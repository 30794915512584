export type InputSizeIdentifier = 'sm' | 'md' | 'lg';

export const baseClassName =
	'border-2 rounded-md active:border-primary-500 focus:outline-none focus:border-primary-500 transition-colors duration-200 ease-in-out';
export const lightThemeClassName =
	'bg-baseWeak-900 text-baseStrong-800 placeholder-baseWeak-500 border-gray-300';
export const darkThemeClassName =
	'dark:bg-baseStrong-700 dark:text-baseWeak-900 dark:placeholder-baseWeak-600 dark:border-baseStrong-800';

export const sizeToClassName: Record<InputSizeIdentifier, string> = {
	sm: 'text-sm px-1',
	md: 'text-md px-2',
	lg: 'text-lg p-2',
};

export const defaultClassName = `min-w-40 w-full ${baseClassName} ${lightThemeClassName} ${darkThemeClassName}`;
