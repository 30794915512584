import { HowToUseArticle } from '@features/how-to-use';
import { CloseDrawerButton, Panel } from '@shared/ui';

// ---

interface HowToUseDrawerProps {
	onClose: () => void;
}

export default function HowToUseDrawer({ onClose }: HowToUseDrawerProps) {
	return (
		<Panel className="flex max-h-full w-[97vw] flex-col rounded-r-none sm:w-96">
			<div className="flex items-center p-2">
				<CloseDrawerButton onClick={onClose} />
			</div>
			<div className="max-h-full flex-1 overflow-auto">
				<HowToUseArticle />
			</div>
		</Panel>
	);
}
