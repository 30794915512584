export default {
	'todo.todos': 'Задачі',
	'todo.addInputPlaceholder': 'Що ви хочете зробити?',
	'todo.add': 'Додати',
	'todo.addTodo': 'Добавить задачу',
	'todo.newTodo': 'Нова задача',
	'todo.moreActions': 'Ще дії',
	'todo.deleteAll': 'Видалити все',
	'todo.deleteCompleted': 'Видалити завершені',
	'todo.deleteTodo': 'Видалити задачу',
	'todo.edit': 'Редагувати',
	'todo.editLabel': 'Редагувати мітку',
	'todo.saveLabel': 'Зберегти мітку',
	'todo.moveTodoToTop': 'Перемістити наверх',
	'todo.markAsCompleted': 'Відзначити як завершене',
	'todo.markAsUncompleted': 'Відзначити як незавершене',
	'todo.emptyList': 'Немає задач',
};
