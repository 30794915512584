import { motion } from 'framer-motion';

// ---

interface AnimatedTimeProps {
	time: string;
}

export default function AnimatedTime({ time }: AnimatedTimeProps) {
	return (
		<motion.div
			key={time}
			initial="initial"
			animate="animate"
			exit="exit"
			variants={{
				initial: { scale: 1 },
				animate: { scale: [1, 1.2, 1] },
				exit: { scale: 1 },
			}}
			transition={{ duration: 0.3 }}
			className="min-w-[5ch] text-center text-4xl font-bold text-baseStrong-800 dark:text-baseWeak-900"
		>
			{time}
		</motion.div>
	);
}
