import { ToggleFullscreen, ThemeSwitcher } from '@features/settings';
import { Button, Gear, Logo } from '@shared/ui';
import { I18nMessage } from '@shared/i18n';
import { HowToUseTrigger } from '@features/how-to-use';
import { TimerCycles } from '@entities/timer';

// ---

interface HeaderProps {
	onToggleSettings: () => void;
	onToggleHowToUse: () => void;
	currentCycle: TimerCycles;
}

export default function HeaderLayout({
	onToggleSettings,
	onToggleHowToUse,
	currentCycle,
}: HeaderProps) {
	return (
		<div
			className={`fixed left-0 top-0 w-full ${currentCycle === TimerCycles.WORK ? 'z-20' : 'z-50'}`}
		>
			<header className="flex justify-between p-4">
				<div>
					<Logo />
				</div>
				<div className="flex">
					<HowToUseTrigger onClick={onToggleHowToUse} />
					<ThemeSwitcher />
					<div className="touch:hidden">
						<ToggleFullscreen />
					</div>
					<Button
						onClick={onToggleSettings}
						textSizeOverride="text-2xl"
						round
						type="nostyles"
						size="lg"
					>
						<Gear />
						<span className="sr-only">
							<I18nMessage id="settings.openSettings" />
						</span>
					</Button>
				</div>
			</header>
		</div>
	);
}
