import { useState } from 'react';
import { motion, useIsPresent } from 'framer-motion';

import { Todo } from '@entities/todo';
import {
	ArrowUp,
	Save,
	Delete,
	Edit,
	Checkbox,
	Button,
	Input,
} from '@shared/ui';
import { I18nMessage } from '@shared/i18n';

// ---

interface TodoListItemProps {
	todoItem: Todo;
	index: number;
	onMoveTodoToTop: (id: string) => void;
	onDelete: (id: string) => void;
	onEdit: (id: string, label: string) => void;
	onToggleComplete: (id: string) => void;
}

export default function TodoListItem({
	todoItem,
	index,
	onToggleComplete,
	onEdit,
	onDelete,
	onMoveTodoToTop,
}: TodoListItemProps) {
	const isPresent = useIsPresent();
	const animations = {
		initial: { scale: 0, opacity: 0 },
		animate: { scale: 1, opacity: 1 },
		exit: { scale: 0, opacity: 0 },
		transition: { type: 'spring', stiffness: 900, damping: 40 },
	};
	const { id, label, completed } = todoItem;
	const [isEditing, setIsEditing] = useState(false);
	const [editedLabel, setEditedLabel] = useState(label);

	return (
		<motion.li
			{...animations}
			layoutId={id}
			className="group/todo-item flex w-full justify-between p-2"
			style={{
				position: isPresent ? 'static' : 'absolute',
			}}
			data-completed={completed ? 'true' : 'false'}
		>
			{isEditing ? (
				<div className="flex w-10/12 gap-2">
					<Input
						className="grow"
						value={editedLabel}
						onChange={(event) => setEditedLabel(event.target.value)}
					/>
				</div>
			) : (
				<div className="flex w-9/12 gap-2">
					<Checkbox
						label={
							<span className="sr-only">
								{completed ? (
									<I18nMessage id="todo.markAsUncompleted" />
								) : (
									<I18nMessage id="todo.markAsCompleted" />
								)}
							</span>
						}
						checked={completed}
						onToggle={() => onToggleComplete(id)}
					/>
					<span className="overflow-hidden text-ellipsis group-data-[completed=true]/todo-item:line-through">
						{label}
					</span>
				</div>
			)}

			{isEditing ? (
				<div className="flex w-1/12 justify-end">
					<Button
						onClick={() => {
							onEdit(id, editedLabel);
							setIsEditing(false);
						}}
						size="sm"
						round
					>
						<span className="sr-only">
							<I18nMessage id={'todo.saveLabel'} />
						</span>
						<Save />
					</Button>
				</div>
			) : (
				<div className="flex w-4/12 justify-end gap-2">
					{index !== 0 && !completed && (
						<Button
							onClick={() => onMoveTodoToTop(id)}
							className="shrink-0"
							size="sm"
							round
						>
							<span className="sr-only">
								<I18nMessage id={'todo.moveTodoToTop'} />
							</span>
							<ArrowUp />
						</Button>
					)}

					<Button
						onClick={() => setIsEditing(!isEditing)}
						className="shrink-0"
						size="sm"
						round
					>
						<span className="sr-only">
							<I18nMessage id={'todo.editLabel'} />
						</span>
						<Edit />
					</Button>

					<Button
						className="shrink-0"
						onClick={() => onDelete(id)}
						size="sm"
						round
					>
						<span className="sr-only">
							<I18nMessage id={'todo.deleteTodo'} />
						</span>
						<Delete />
					</Button>
				</div>
			)}
		</motion.li>
	);
}

TodoListItem.defaultProps = {
	onDelete: () => {},
	onEdit: () => {},
	onToggleComplete: () => {},
};
