export default {
	'todo.todos': 'Задачи',
	'todo.addInputPlaceholder': 'Что нужно сделать?',
	'todo.add': 'Добавить',
	'todo.addTodo': 'Добавить задачу',
	'todo.newTodo': 'Новая задача',
	'todo.moreActions': 'Еще действия',
	'todo.deleteAll': 'Удалить все',
	'todo.deleteCompleted': 'Удалить завершенные',
	'todo.deleteTodo': 'Удалить задачу',
	'todo.edit': 'Редактировать',
	'todo.editLabel': 'Редактировать метку',
	'todo.saveLabel': 'Сохранить метку',
	'todo.moveTodoToTop': 'Переместить наверх',
	'todo.markAsCompleted': 'Отметить как завершенное',
	'todo.markAsUncompleted': 'Отметить как незавершенное',
	'todo.emptyList': 'Нет задач',
};
