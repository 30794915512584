import { TimerCycles, TimerCyclesWithoutIdle } from '@entities/timer';
import { Text, Marquee } from '@shared/ui';
import { I18nMessage } from '@shared/i18n';
import { Todo } from '@entities/todo';

// ---

interface CurrentActivityLabelProps {
	selectedCycle: TimerCyclesWithoutIdle;
	currentTask?: Todo;
}

export default function CurrentActivityLabel({
	selectedCycle,
	currentTask,
}: CurrentActivityLabelProps) {
	const isWork = selectedCycle === TimerCycles.WORK;
	const isBreak = selectedCycle === TimerCycles.BREAK;
	const isLongBreak = selectedCycle === TimerCycles.LONG_BREAK;

	return (
		<div className="mx-auto h-6 w-full max-w-[14rem] text-center  font-semibold">
			{isWork && currentTask && <Marquee>{currentTask.label}</Marquee>}
			{isBreak && (
				<Text elementType="span">
					<I18nMessage id="timer.break" />
				</Text>
			)}
			{isLongBreak && (
				<Text elementType="span">
					<I18nMessage id="timer.longBreak" />
				</Text>
			)}
		</div>
	);
}
