import { ReactNode } from 'react';
import {
	Button,
	Key,
	Label,
	ListBox,
	ListBoxItem,
	Popover,
	Select as AriaSelect,
	SelectValue,
} from 'react-aria-components';

import { ChevronUpDown } from '@shared/ui';

// ---

interface Item {
	label: ReactNode;
	value: Key;
}

interface SelectProps {
	label?: string;
	value: Key;
	className?: string;
	onSelectionChange: (value: Key) => void;
	items: Item[];
}

export default function Select({
	label,
	value,
	onSelectionChange,
	items,
	className,
}: SelectProps) {
	return (
		<AriaSelect
			className={`flex min-w-40 flex-col gap-1 ${className}`}
			selectedKey={value}
			onSelectionChange={onSelectionChange}
		>
			{label && (
				<Label className="cursor-default text-baseStrong-800 dark:text-baseWeak-900">
					{label}
				</Label>
			)}
			<Button
				data-testid="select-trigger"
				className="pressed:bg-opacity-100 flex cursor-default items-center rounded-lg border-2 bg-baseWeak-900 bg-opacity-90 py-2 pl-5 pr-2 text-left text-base leading-normal text-gray-700 shadow-md outline-none ring-white ring-offset-2 ring-offset-rose-700 transition focus:outline-none focus-visible:outline-none dark:border-baseStrong-700 dark:bg-baseStrong-700 dark:text-baseWeak-900"
			>
				<SelectValue className="flex-1 truncate placeholder-shown:italic" />
				<span aria-hidden="true">
					<ChevronUpDown />
				</span>
			</Button>
			<Popover
				className="entering:animate-in entering:fade-in exiting:animate-out exiting:fade-out max-h-60 w-[--trigger-width] overflow-auto rounded-md bg-baseWeak-900 text-base shadow-lg ring-1 ring-black/5 dark:bg-baseStrong-700 dark:text-baseWeak-900"
				placement="bottom"
			>
				<ListBox className="p-1 outline-none">
					{items.map((item) => (
						<ListBoxItem
							className="group flex cursor-default select-none items-center gap-2 rounded px-4 py-2 text-gray-900 outline-none focus:bg-primary-600 focus:text-white dark:bg-baseStrong-700 dark:text-baseWeak-900"
							key={item.value}
							id={item.value}
						>
							{item.label}
						</ListBoxItem>
					))}
				</ListBox>
			</Popover>
		</AriaSelect>
	);
}

Select.defaultProps = {
	className: '',
	label: '',
};
