import { Key } from 'react-aria-components';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { Select } from '@shared/ui';
import { selectLongBreakEvery, setLongBreakEvery } from '@entities/setting';

// ---

export default function LongBreakFrequencySelect() {
	const longBreakEvery = useSelector(selectLongBreakEvery);
	const dispatch = useDispatch();
	const intl = useIntl();
	const label = intl.formatMessage({ id: 'settings.longBreakFrequency' });

	return (
		<Select
			label={label}
			value={longBreakEvery}
			onSelectionChange={(value: Key) => {
				dispatch(setLongBreakEvery(value as number));
			}}
			items={[
				{ value: 2, label: '2' },
				{ value: 3, label: '3' },
				{ value: 4, label: '4' },
				{ value: 5, label: '5' },
				{ value: 6, label: '6' },
			]}
		/>
	);
}
