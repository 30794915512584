import { ReactNode } from 'react';

// ---

interface MainPageTimerWrapperProps {
	children: ReactNode;
}

export default function MainPageTimerWrapper({
	children,
}: MainPageTimerWrapperProps) {
	return (
		<div
			data-mode="dark"
			className="flex h-[80vw] w-[80vw] lg:h-[27vw] lg:w-[27vw] touch:landscape:h-[40vw] touch:landscape:max-h-[calc(100vh-5rem)] touch:landscape:w-[40vw] touch:landscape:max-w-[calc(100vh-5rem)]"
		>
			{children}
		</div>
	);
}
