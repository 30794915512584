import { useDispatch, useSelector } from 'react-redux';
import { Key } from 'react-aria-components';
import { useIntl } from 'react-intl';

import { Select } from '@shared/ui';
import { selectLanguage, setLanguage } from '@entities/setting';
import { Locales } from '@shared/i18n';

// ---

export default function LanguageSelect() {
	const language = useSelector(selectLanguage);
	const dispatch = useDispatch();
	const intl = useIntl();

	const label = intl.formatMessage({ id: 'settings.language' });

	return (
		<Select
			label={label}
			value={language}
			onSelectionChange={(value: Key) => {
				dispatch(setLanguage(value as Locales));
			}}
			items={[
				{ value: Locales.ENGLISH, label: 'English' },
				{ value: Locales.RUSSIAN, label: 'Русский' },
				{ value: Locales.UKRAINIAN, label: 'Українська' },
			]}
		/>
	);
}
