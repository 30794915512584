import useFullscreen from '../../lib/use-fullscreen/use-fullscreen.ts';
import { Button, Minimize, Expand } from '@shared/ui';
import { I18nMessage } from '@shared/i18n';

// ---

export default function ToggleFullscreen() {
	const [isFullscreen, toggleFullScreen] = useFullscreen();

	return (
		<Button
			type="nostyles"
			round
			size="lg"
			textSizeOverride="text-2xl"
			onClick={() => toggleFullScreen()}
		>
			{isFullscreen ? <Minimize /> : <Expand />}
			<span className="sr-only">
				<I18nMessage
					id={
						isFullscreen
							? 'settings.exitFullscreenMode'
							: 'settings.enterFullscreenMode'
					}
				/>
			</span>
		</Button>
	);
}
