import { AnimatePresence } from 'framer-motion';

import { Todo } from '@entities/todo';
import { EmptyCircle } from '@shared/ui';
import { I18nMessage } from '@shared/i18n';

import TodoListItem from './TodoListItem.tsx';

// ---

interface TodoListProps {
	todos: Todo[];
	onDelete: (id: string) => void;
	onEdit: (id: string, label: string) => void;
	onMoveTodoToTop: (id: string) => void;
	onToggleComplete: (id: string) => void;
}

export default function TodoList({
	todos,
	onToggleComplete,
	onEdit,
	onDelete,
	onMoveTodoToTop,
}: TodoListProps) {
	return (
		<>
			{todos.length === 0 && (
				<div className="flex flex-col items-center p-4 text-gray-400 dark:text-baseStrong-400">
					<div className="text-4xl">
						<EmptyCircle />
					</div>
					<p>
						<I18nMessage id="todo.emptyList" />
					</p>
				</div>
			)}
			<div className="flex max-h-[calc(100%-6.5rem)] grow flex-col overflow-y-auto">
				<ul className="h-full overflow-x-hidden overflow-y-visible">
					<AnimatePresence>
						{todos
							.toSorted((a, b) => {
								if (
									(a.completed && b.completed) ||
									(!a.completed && !b.completed)
								) {
									return 0;
								}

								if (a.completed) {
									return 1;
								}

								return -1;
							})
							.map((todo, index) => (
								<TodoListItem
									index={index}
									key={todo.id}
									todoItem={todo}
									onDelete={onDelete}
									onEdit={onEdit}
									onMoveTodoToTop={onMoveTodoToTop}
									onToggleComplete={onToggleComplete}
								/>
							))}
					</AnimatePresence>
				</ul>
			</div>
		</>
	);
}
