import { useState } from 'react';
import DefinedDrawers from './defined-drawers.ts';

// ---

export default function useDrawers() {
	const [shownDrawer, setShownDrawer] = useState<DefinedDrawers | null>(null);

	const toggleDrawer = (drawerToToggle: DefinedDrawers) => {
		if (drawerToToggle === shownDrawer) {
			setShownDrawer(null);
			return;
		}

		setShownDrawer(drawerToToggle);
	};

	return {
		shownDrawer,
		toggleDrawer,
	};
}
