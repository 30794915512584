export default function Delete() {
	return (
		<svg
			width="1em"
			height="1em"
			viewBox="0 -0.5 21 21"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g
					id="Dribbble-Light-Preview"
					transform="translate(-179.000000, -360.000000)"
					fill="currentColor"
				>
					<g transform="translate(56.000000, 160.000000)">
						<path
							d="M130.35,216 L132.45,216 L132.45,208 L130.35,208 L130.35,216 Z M134.55,216 L136.65,216 L136.65,208 L134.55,208 L134.55,216 Z M128.25,218 L138.75,218 L138.75,206 L128.25,206 L128.25,218 Z M130.35,204 L136.65,204 L136.65,202 L130.35,202 L130.35,204 Z M138.75,204 L138.75,200 L128.25,200 L128.25,204 L123,204 L123,206 L126.15,206 L126.15,220 L140.85,220 L140.85,206 L144,206 L144,204 L138.75,204 Z"
							id="delete-[#1487]"
						></path>
					</g>
				</g>
			</g>
		</svg>
	);
}
