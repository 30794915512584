import { ReactNode } from 'react';
import {
	Menu,
	MenuItem,
	MenuTrigger,
	Popover,
	Key,
} from 'react-aria-components';

// ---

interface PopoverMenuItem {
	id: string;
	label: ReactNode;
}

interface PopoverMenuProps {
	Trigger: () => ReactNode;
	items: PopoverMenuItem[];
	onAction: (id: Key) => void;
}

export default function PopoverMenu({
	Trigger,
	items,
	onAction,
}: PopoverMenuProps) {
	return (
		<MenuTrigger>
			<Trigger />
			<Popover className="rounded-lg border-2 border-gray-300 bg-baseWeak-900 p-2 text-baseStrong-800 shadow">
				<Menu onAction={onAction} className="outline-none">
					{items.map((item) => (
						<MenuItem
							className="rounded-md px-1 hover:cursor-pointer hover:bg-primary-500 data-[focused=true]:outline-none"
							key={item.id}
							id={item.id}
						>
							{item.label}
						</MenuItem>
					))}
				</Menu>
			</Popover>
		</MenuTrigger>
	);
}
