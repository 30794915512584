export default {
	'user.whatsYourName': 'Як вас звати?',
	'user.niceToMeetYou': 'Приємно познайомитися, {name}!',
	'user.hello': 'Привіт!',
	'user.username': "Ім'я користувача",
	'user.defaultUsername': 'Підозрілий незнайомець',

	'user.fullName': 'Повне ім’я',
	'user.anonymous': 'Анонімно',
	'user.email': 'Email',
	'user.yourEmail': 'Ваш Email',
	'user.feedback': 'Відгук',
	'user.yourFeedback': 'Ваш відгук',
	'user.chooseTopic': 'Оберіть тему',

	'user.validationInvalidEmail':
		'Будь ласка, введіть правильну адресу електронної пошти',
	'user.validationFillOutThisField': 'Будь ласка, заповніть це поле',
	'user.feedbackSuccess': 'Відгук успішно надіслано!',
	'user.feedbackFail':
		'Не вдалося надіслати відгук. Будь ласка, спробуйте пізніше.',
	'user.feedbackSubmitting': 'Надсилаємо...',

	'user.sendFeedback': 'Надіслати відгук',
	'user.feedbackTopicGeneral': 'Відгук',
	'user.feedbackTopicBug': 'Повідомлення про помилку',
	'user.feedbackTopicComplaint': 'Скарга',
	'user.feedbackTopicFeature': 'Запит нового функціоналу',
	'user.feedbackTopicContact': 'Зв’язатися з розробником',
	'user.feedbackTopicOther': 'Інше',
};
