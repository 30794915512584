import { useSelector, useDispatch } from 'react-redux';
import { selectIsFullscreen, setFullscreen } from '@entities/setting';
import { useEffect } from 'react';

// ---

export default function useFullscreen(): [boolean, () => void] {
	const dispatch = useDispatch();
	const isFullscreen = useSelector(selectIsFullscreen);

	useEffect(() => {
		const handleFullscreenChange = () => {
			dispatch(setFullscreen(!!document.fullscreenElement));
		};

		document.addEventListener('fullscreenchange', handleFullscreenChange);

		return () => {
			document.removeEventListener('fullscreenchange', handleFullscreenChange);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const toggleFullscreen = () => {
		const canDoFullscreen = !!document.documentElement.requestFullscreen;

		if (!canDoFullscreen) {
			return;
		}

		if (isFullscreen) {
			document.exitFullscreen();
		} else {
			document.documentElement.requestFullscreen();
		}
	};

	return [isFullscreen, toggleFullscreen];
}
