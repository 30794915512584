interface WorkCurtainBackgroundProps {
	show: boolean;
}

export default function WorkCurtainBackground({
	show,
}: WorkCurtainBackgroundProps) {
	return (
		<div
			className={`${show ? 'opacity-100' : 'opacity-0'} absolute left-0 top-0 z-30 h-full w-full bg-radial-gradient from-baseStrong-800 to-black duration-1000`}
		></div>
	);
}
