import { useState } from 'react';

import {
	LongBreakFrequencySelect,
	LanguageSelect,
	AutoStartToggle,
} from '@features/settings';
import { I18nMessage } from '@shared/i18n';
import { SendFeedbackToggle, SendFeedbackForm } from '@features/feedback';
import { Button, Panel, Cross, CloseDrawerButton } from '@shared/ui';

// ---

interface SettingsDrawerProps {
	onClose: () => void;
}

export default function SettingsDrawer({ onClose }: SettingsDrawerProps) {
	const [showFeedbackModal, setShowFeedbackModal] = useState(false);

	return (
		<Panel className="flex max-h-full w-[97vw] flex-col rounded-r-none sm:w-96">
			<div className="flex items-center">
				<CloseDrawerButton onClick={onClose} />
				<div className="grow text-center font-semibold">
					<I18nMessage id="settings.settings" />
				</div>
			</div>
			<div className="relative mt-4  flex-grow ">
				<div
					className={`absolute bottom-0 left-0 top-0 flex h-full w-full flex-col gap-3 overflow-auto ${showFeedbackModal ? 'hidden' : 'block'}`}
				>
					<LongBreakFrequencySelect />
					<LanguageSelect />
					<AutoStartToggle />
					<SendFeedbackToggle onClick={() => setShowFeedbackModal(true)} />
				</div>
				<div
					className={`absolute bottom-0 left-0 top-0 h-full w-full overflow-auto ${showFeedbackModal ? 'block' : 'hidden'}`}
				>
					<div className="flex justify-end">
						<Button
							type="nostyles"
							round
							className="m-2"
							onClick={() => setShowFeedbackModal(false)}
							textSizeOverride="text-xl"
						>
							<Cross />
						</Button>
					</div>
					<SendFeedbackForm />
				</div>
			</div>
		</Panel>
	);
}
