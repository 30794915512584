import { Button } from '@shared/ui';
import { I18nMessage } from '@shared/i18n';

// ---

interface SendFeedbackToggleProps {
	onClick: () => void;
}

export default function SendFeedbackToggle({
	onClick,
}: SendFeedbackToggleProps) {
	return (
		<Button size="md" onClick={onClick} type="primary">
			<I18nMessage id={'user.sendFeedback'} />
		</Button>
	);
}
