import useCurrentTheme from './use-current-theme.ts';
import { useEffect } from 'react';

// ---

export default function useThemeAttr(
	element: HTMLElement | null,
	overrideTheme?: string
) {
	const theme = useCurrentTheme();

	useEffect(() => {
		if (!element) {
			return;
		}

		element.dataset.theme = overrideTheme || theme;
		return () => {
			element.dataset.theme = '';
		};
	}, [element, overrideTheme, theme]);
}
