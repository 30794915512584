import { configureStore } from '@reduxjs/toolkit';

import { historyReducer } from '@entities/history';
import { timerReducer } from '@entities/timer';
import { todoReducer } from '@entities/todo';
import { userReducer } from '@entities/user';
import { settingsReducer } from '@entities/setting';
import { localStorageMiddleware } from '@shared/lib';

// ---

export const appStore = configureStore({
	reducer: {
		history: historyReducer,
		timer: timerReducer,
		todo: todoReducer,
		user: userReducer,
		settings: settingsReducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(
			localStorageMiddleware(['user', 'todo', 'settings', 'history', 'timer'])
		),
	devTools: process.env.NODE_ENV !== 'production',
});
