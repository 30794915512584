import { motion } from 'framer-motion';
import { ReactNode } from 'react';

// ---

interface PageAnimationWrapperProps {
	children: ReactNode;
	className?: string;
	layoutId: string;
}

export default function PageAnimationWrapper({
	children,
	className,
	layoutId,
}: PageAnimationWrapperProps) {
	return (
		<motion.div
			layoutId={layoutId}
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 0.5 }}
			className={className}
		>
			{children}
		</motion.div>
	);
}
