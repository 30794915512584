import { useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';

import { selectTheme, Theme } from '../../model';

// ---

function getActualTheme(theme: Theme, colorSchemeQuery: MediaQueryList) {
	if (theme === Theme.LIGHT || theme === Theme.DARK) {
		return theme;
	}

	if (theme === Theme.SYSTEM) {
		return colorSchemeQuery.matches ? Theme.DARK : Theme.LIGHT;
	}
}

export default function useCurrentTheme() {
	const colorSchemeQuery = useMemo(
		() => window.matchMedia('(prefers-color-scheme: dark)'),
		[]
	);
	const theme = useSelector(selectTheme);
	const [actualTheme, setActualTheme] = useState(
		getActualTheme(theme, colorSchemeQuery)
	);

	useEffect(() => {
		setActualTheme(getActualTheme(theme, colorSchemeQuery));
		const listener = () => {
			setActualTheme(getActualTheme(theme, colorSchemeQuery));
		};

		colorSchemeQuery.addEventListener('change', listener);

		return () => colorSchemeQuery.removeEventListener('change', listener);
	}, [theme, colorSchemeQuery]);

	return actualTheme;
}
