export default {
	'settings.settings': 'Настройки',
	'settings.language': 'Язык',
	'settings.theme': 'Тема',
	'settings.longBreakFrequency': 'Частота разугрузки',
	'settings.enableAutoStart': 'Включить автозапуск',
	'settings.enterFullscreenMode': 'Войти в полноэкранный режим',
	'settings.exitFullscreenMode': 'Выйти из полноэкранного режима',

	'settings.switchToLightTheme': 'Переключить светлую тему',
	'settings.switchToDarkTheme': 'Переключить темную тем',
	'settings.switchToSystemTheme': 'Переключить системную тему',

	'settings.openSettings': 'Открыть настройки',
};
