import { createSlice } from '@reduxjs/toolkit';
import { loadStateFromLocalStorage } from '@shared/lib';

// ---

interface UserState {
	username: string;
	sessionStarted: boolean;
}

const initialState: UserState = {
	username: '',
	sessionStarted: false,
};

const userSlice = createSlice({
	name: 'user',
	initialState: loadStateFromLocalStorage('user') || initialState,
	reducers: {
		setUsername(state, action) {
			state.username = action.payload;
		},
		startSession(state) {
			state.sessionStarted = true;
		},
	},
});

export const selectUsername = (state: { user: UserState }) =>
	state.user.username;

export const { setUsername, startSession } = userSlice.actions;

export const userReducer = userSlice.reducer;
