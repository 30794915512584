import { ReactNode } from 'react';
import { Button as AriaButton, PressEvent } from 'react-aria-components';

// ---

type ButtonSizeIdentifier = 'sm' | 'md' | 'lg';
type ButtonTypeIdentifier = 'primary' | 'secondary' | 'nostyles';
type ButtonHTMLType = 'button' | 'submit' | 'reset';

interface ButtonProps {
	children: ReactNode;
	onClick?: () => void;
	onMouseDown?: (event: PressEvent) => void;
	onMouseUp?: (event: PressEvent) => void;
	className: string;
	type: ButtonTypeIdentifier;
	round?: boolean;
	htmlType?: ButtonHTMLType;
	size: ButtonSizeIdentifier;
	/**
	 * Optional text size override. Accepts any valid tailwindcss text size class.
	 * */
	textSizeOverride?: string;
	disabled?: boolean;
}

const baseClasses = 'font-semibold';
const lightThemeClasses = '';
const darkThemeClasses = '';

const defaultClasses = `${baseClasses} ${lightThemeClasses} ${darkThemeClasses}`;

const focusClasses =
	'focus:outline-none focus:ring-2 focus:ring-primary-900 focus:ring-opacity-50';

const typeClasses: Record<ButtonTypeIdentifier, string[]> = {
	primary: [
		'bg-primary-600 text-white',
		'hover:bg-primary-700 hover:bg-opacity-90',
		focusClasses,
		'data-[pressed=true]:bg-primary-900',
		'disabled:bg-gray-300 disabled:text-gray-500 disabled:cursor-not-allowed',
	],
	secondary: [
		'border border-primary-900 bg-transparent text-primary-900 text-primary-900',
		'hover:bg-primary-600 hover:bg-opacity-90 hover:text-white',
		focusClasses,
	],
	nostyles: ['text-baseStrong-900 dark:text-baseWeak-900', focusClasses],
};

const sizeClassesDefault: Record<ButtonSizeIdentifier, string> = {
	sm: 'py-1 p-2 text-sm rounded-3xl',
	md: 'py-1 px-3 text-md rounded-3xl',
	lg: 'py-2 px-4 text-lg rounded-3xl',
};

const sizeToTextSize: Record<ButtonSizeIdentifier, string> = {
	sm: 'text-sm',
	md: 'text-md',
	lg: 'text-lg',
};

const sizeClassesRound: Record<ButtonSizeIdentifier, string> = {
	sm: 'h-7 w-7 flex items-center justify-center rounded-full',
	md: 'h-9 w-9 flex items-center justify-center rounded-full',
	lg: 'h-11 w-11 flex items-center justify-center rounded-full',
};

export default function Button({
	children,
	size,
	type,
	htmlType,
	round,
	disabled,
	className,
	onClick,
	onMouseDown,
	onMouseUp,
	textSizeOverride,
}: ButtonProps) {
	const sizeClasses = (round ? sizeClassesRound : sizeClassesDefault)[size];
	return (
		<AriaButton
			isDisabled={disabled}
			type={htmlType}
			className={`${defaultClasses} ${typeClasses[type].join(' ')} ${textSizeOverride ? textSizeOverride : sizeToTextSize[size]} ${sizeClasses} ${className}`}
			onPress={onClick}
			onPressStart={onMouseDown}
			onPressEnd={onMouseUp}
		>
			{children}
		</AriaButton>
	);
}

Button.defaultProps = {
	size: 'md' as ButtonSizeIdentifier,
	type: 'primary' as ButtonTypeIdentifier,
	className: '',
	round: false,
	htmlType: 'button' as ButtonHTMLType,
	disabled: false,
};
