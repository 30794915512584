import { v4 as uuidv4 } from 'uuid';

// ---

export interface NewTodo {
	label: string;
}

export interface Todo extends NewTodo {
	id: string;
	completed: boolean;
}

export function CreateTodo(label: string): Todo {
	return {
		id: uuidv4(),
		label,
		completed: false,
	};
}
