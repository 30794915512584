export default {
	'settings.settings': 'Settings',
	'settings.language': 'Language',
	'settings.theme': 'Theme',
	'settings.longBreakFrequency': 'Long break frequency',
	'settings.enableAutoStart': 'Enable auto start',
	'settings.enterFullscreenMode': 'Enter fullscreen mode',
	'settings.exitFullscreenMode': 'Exit fullscreen mode',

	'settings.switchToLightTheme': 'Switch to light theme',
	'settings.switchToDarkTheme': 'Switch to dark theme',
	'settings.switchToSystemTheme': 'Switch to system theme',

	'settings.openSettings': 'Open settings',
};
