import {
	Label,
	Slider as AriaSlider,
	SliderOutput,
	SliderThumb,
	SliderTrack,
} from 'react-aria-components';
import { ReactNode } from 'react';

// ---

interface SliderProps {
	label: ReactNode;
	thumb?: ReactNode;
	defaultValue?: number;
	value: number;
	onChange: (value: number) => void;
}

interface FillProps {
	percent: number;
}

function Track() {
	return (
		<div className="absolute top-[50%] h-2 w-full translate-y-[-50%] rounded-full bg-baseStrong-600 duration-300 dark:bg-white/40" />
	);
}

function Fill({ percent }: FillProps) {
	return (
		<div
			className="absolute top-[50%] h-2 translate-y-[-50%] rounded-full bg-baseStrong-800 transition-colors duration-300 dark:bg-white"
			style={{ width: percent + '%' }}
		/>
	);
}

export default function Slider({
	label,
	thumb,
	value,
	defaultValue,
	onChange,
}: SliderProps) {
	return (
		<div className="group/volume mx-auto w-full">
			<AriaSlider
				onChange={onChange}
				defaultValue={defaultValue}
				value={value}
				className="w-full"
			>
				<div className="sr-only flex text-white">
					<Label className="flex-1">{label}</Label>
					<SliderOutput />
				</div>
				<SliderTrack className="relative h-7 w-full">
					{({ state }) => (
						<>
							<Track />
							<Fill percent={state.getThumbPercent(0) * 100} />
							<SliderThumb className="dragging:bg-purple-100 top-[50%] h-7 w-7 rounded-full border-solid border-baseStrong-900 bg-baseStrong-900 outline-none ring-black transition duration-300 group-focus-within/volume:outline-none group-focus-within/volume:ring-2 group-focus-within/volume:ring-primary-900 group-focus-within/volume:ring-opacity-50 dark:bg-white">
								<div className="flex h-full flex-col items-center justify-center text-lg text-white dark:text-baseStrong-800">
									{thumb}
								</div>
							</SliderThumb>
						</>
					)}
				</SliderTrack>
			</AriaSlider>
		</div>
	);
}
