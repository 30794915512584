export default {
	'timer.break': 'Break',
	'timer.longBreak': 'Chill',
	'timer.work': 'Work',

	'timer.startCycle': 'Start {cycle} cycle',

	'timer.editCurrentTime': 'Edit current time',
	'timer.cancelTimeEdit': 'Cancel edit time',
	'timer.saveTime': 'Save time',

	'timer.changeTimeTo': 'Change time to {time} minutes',
};
