import { StrictMode } from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';

import { appStore } from './model';
import { checkEnv } from './lib';
import App from './App.tsx';

// ---

const appRoot = createRoot(document.getElementById('root')!);

checkEnv();

Sentry.init({
	dsn: import.meta.env.VITE_SENTRY_DSN,
	integrations: [Sentry.browserTracingIntegration()],
	// Performance Monitoring
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
	environment: import.meta.env.MODE,
});

appRoot.render(
	<StrictMode>
		<Provider store={appStore}>
			<App />
		</Provider>
	</StrictMode>
);
