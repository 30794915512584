import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { selectAutostart, setAutostart } from '@entities/setting';
import { Checkbox } from '@shared/ui';

// ---

export default function AutoStartToggle() {
	const autoStart: boolean = useSelector(selectAutostart);
	const dispatch = useDispatch();
	const intl = useIntl();

	const label = intl.formatMessage({ id: 'settings.enableAutoStart' });

	const handleToggle = () => {
		dispatch(setAutostart(!autoStart));
	};

	return (
		<div>
			<Checkbox label={label} checked={autoStart} onToggle={handleToggle} />
		</div>
	);
}
