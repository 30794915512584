import { createSlice } from '@reduxjs/toolkit';
import { CreateTodo, NewTodo, Todo } from './model.ts';
import { loadStateFromLocalStorage } from '@shared/lib';

// ---

const initialState: {
	todos: Todo[];
} = {
	todos: [],
};

const todoSlice = createSlice({
	name: 'todo',
	initialState: loadStateFromLocalStorage('todo') || initialState,
	reducers: {
		addTodo(state, action: { type: string; payload: NewTodo }) {
			state.todos.unshift(CreateTodo(action.payload.label));
		},
		markComplete(state, action: { type: string; payload: string }) {
			const todo = state.todos.find((todo: Todo) => todo.id === action.payload);
			if (todo) {
				todo.completed = true;
			}
		},
		unmarkComplete(state, action: { type: string; payload: string }) {
			const todo = state.todos.find((todo: Todo) => todo.id === action.payload);
			if (todo) {
				todo.completed = false;
			}
		},
		toggleComplete(state, action: { type: string; payload: string }) {
			const todo = state.todos.find((todo: Todo) => todo.id === action.payload);
			if (todo) {
				todo.completed = !todo.completed;
			}
		},
		editTodo(
			state,
			action: { type: string; payload: { id: string; label: string } }
		) {
			const todo = state.todos.find(
				(todo: Todo) => todo.id === action.payload.id
			);
			if (todo) {
				todo.label = action.payload.label;
			}
		},
		removeTodo(state, action: { payload: string }) {
			state.todos = state.todos.filter(
				(todo: Todo) => todo.id !== action.payload
			);
		},
		removeCompleted(state) {
			state.todos = state.todos.filter((todo: Todo) => !todo.completed);
		},
		removeAll(state) {
			state.todos = [];
		},
		moveTodoToTop(state, action: { payload: string }) {
			const todo = state.todos.find((todo: Todo) => todo.id === action.payload);
			if (todo) {
				state.todos = state.todos.filter(
					(todo: Todo) => todo.id !== action.payload
				);
				state.todos.unshift(todo);
			}
		},
	},
});

export const {
	editTodo,
	toggleComplete,
	addTodo,
	markComplete,
	unmarkComplete,
	removeTodo,
	moveTodoToTop,
	removeCompleted,
	removeAll,
} = todoSlice.actions;

export const selectTodos = (state: { todo: { todos: Todo[] } }) =>
	state.todo.todos;

export const todoReducer = todoSlice.reducer;
