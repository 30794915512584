export default {
	'todo.todos': 'Todos',
	'todo.addInputPlaceholder': 'What needs to be done?',
	'todo.add': 'Add',
	'todo.addTodo': 'Add a Todo',
	'todo.newTodo': 'New Todo',
	'todo.moreActions': 'More actions',
	'todo.deleteAll': 'Delete all',
	'todo.deleteCompleted': 'Delete completed',
	'todo.deleteTodo': 'Delete todo',
	'todo.edit': 'Edit',
	'todo.editLabel': 'Edit label',
	'todo.saveLabel': 'Save label',
	'todo.moveTodoToTop': 'Move to top',
	'todo.markAsCompleted': 'Mark as completed',
	'todo.markAsUncompleted': 'Mark as uncompleted',
	'todo.emptyList': 'No todos',
};
