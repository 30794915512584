import { ReactNode, JSX } from 'react';

import { Button } from '@shared/ui';
import { TimerCycles, TimerCyclesWithoutIdle } from '@entities/timer';
import { I18nMessage } from '@shared/i18n';

// ---

const cycles: {
	handle: TimerCyclesWithoutIdle;
	label: ReactNode | JSX.Element;
}[] = [
	{
		label: <I18nMessage id="timer.work" />,
		handle: TimerCycles.WORK,
	},
	{
		label: <I18nMessage id="timer.break" />,
		handle: TimerCycles.BREAK,
	},
	{
		label: <I18nMessage id="timer.longBreak" />,
		handle: TimerCycles.LONG_BREAK,
	},
];

interface CycleSwitcherProps {
	currentCycle: TimerCyclesWithoutIdle;
	onCycleChange: (cycle: TimerCyclesWithoutIdle) => void;
}

export default function CycleSwitcher({
	currentCycle,
	onCycleChange,
}: CycleSwitcherProps) {
	return (
		<div className="flex items-center justify-between p-3">
			{cycles.map(({ handle, label }) => (
				<div className="flex flex-1 justify-center" key={handle}>
					<Button
						size="lg"
						type="nostyles"
						className={
							handle === currentCycle
								? 'bg-baseWeak-900 bg-opacity-20 font-bold duration-300'
								: ''
						}
						onClick={() => onCycleChange(handle)}
					>
						{label}
					</Button>
				</div>
			))}
		</div>
	);
}
