import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { AnimatePresence } from 'framer-motion';
import { useSelector } from 'react-redux';

import MainPage from '@pages/main';
import { useThemeAttr, selectLanguage } from '@entities/setting';
import { useHistoryLogRefreshes } from '@features/history';
import { isTouch } from '@shared/lib';
import { I18nProvider } from './i18n';

import './ui';

// ---

interface ErrorFallbackProps {
	error: Error;
}

function ErrorFallback({ error }: ErrorFallbackProps) {
	return <div>{error.message}</div>;
}

function App() {
	const currentLocale = useSelector(selectLanguage);

	useThemeAttr(document.body);
	useHistoryLogRefreshes();

	useEffect(() => {
		document.documentElement.dataset.touch = isTouch(window) ? 'true' : 'false';

		// Temporary hack to remove the pathname from the URL
		// as the app only has one page
		if (location.pathname !== '/') {
			history.replaceState(null, '', '/');
		}
	}, []);

	return (
		<div className="flex flex-1 flex-col text-baseStrong-900 dark:text-baseWeak-900">
			<I18nProvider locale={currentLocale}>
				<ErrorBoundary FallbackComponent={ErrorFallback}>
					<AnimatePresence mode="wait">
						<MainPage />
					</AnimatePresence>
				</ErrorBoundary>
			</I18nProvider>
		</div>
	);
}

export default App;
